/* eslint-disable */

import {
    Autocomplete,
    Button,
    CircularProgress,
    Container,
    IconButton,
    // InputAdornment,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TextField,
    Typography,
    TableBody,
    Card,
    ButtonGroup,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router"
// import { LineItemTypeUpdate } from "src/@types/lineItemType"
import { QuoteUpdate } from "src/@types/quote"
import { LineItemType } from "src/@types/lineItem"
import AddIcon from "@mui/icons-material/Add"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import QuoteEditForm from "src/forms/quote-edit"
import { getLineItemTypes } from "src/redux/slices/lineItemTypes"
import { getCategories } from "src/redux/slices/categories"
import {
    addFeeToQuote,
    addFinancingOptionToQuote,
    createLineItem,
    // createQuoteSection,
    deleteFee,
    deleteFinancingOption,
    getQuote,
    reorderQuoteFees,
    reorderQuoteLineItems,
    updateFinancingOption,
    updateQuote,
} from "src/redux/slices/quotes"
// import { getMaterials } from "src/redux/slices/materials"
// import { getMeasurementTypes } from "src/redux/slices/measurementTypes"
import { FeeTypeType } from "src/@types/fee-type"
import { FeeType } from "src/@types/fee"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { Category } from "src/@types/category"
// import { createLineItem, getLineItemsForQuote, saveLineItemsForQuote } from "src/redux/slices/line-items"
import { LineItemTypeType } from "src/@types/lineItemType"
import { getFeeTypes } from "src/redux/slices/fee-types"
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd"
import LineItemDetails from "./line-item-details"
import QuoteSectionDetails from "./quote-section-details"
import { Permissions } from "src/@types/security"
import { useAuthContext } from "src/auth/useAuthContext"
import { apiRecalculateMaterialsQuantity } from "src/api/quotes"
import { getFinancingOptionTypes } from "src/redux/slices/financing-option-types"
import { TFinancingOption, TFinancingOptionUpdate } from "src/@types/financing-option"
import { TFinancingOptionType } from "src/@types/financing-option-type"
import TextFieldWithSave from "./text-field-with-save"

const getAllCategoryChildren = (id: number | null, categories: Category[]): Category[] => {
    if (id === null) return []
    const children = categories.filter((item) => item.parent_id === id)
    if (children.length === 0) return []

    return children.reduce((acc: Category[], category: Category) => {
        if (acc.includes(category)) return acc
        return acc.concat(category, getAllCategoryChildren(category.id, categories))
    }, [])
}

export default function QuoteDetailsPage() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [feesMenuOpen, setFeesMenuOpen] = useState<boolean>(false)
    const [financingOptionsMenuOpen, setFinancingOptionsMenuOpen] = useState<boolean>(false)
    // const open = Boolean(anchorEl)
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
        setFeesMenuOpen(false)
        setFinancingOptionsMenuOpen(false)
    }

    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const { hasPermission } = useAuthContext()

    const [isLoading, setIsLoading] = useState(true)
    const [lineItemTypeToCreate, setLineItemTypeToCreate] = useState<LineItemTypeType | null>(null)
    const [isSending, setIsSending] = useState(false)
    const [expandedLineItemId, setExpandedLineItemId] = useState<number | null>(null)

    const navigate = useNavigate()

    // const [quoteSectionName, setQuoteSectionName] = useState("")

    const dispatch = useDispatch()
    // const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        // dispatch(getMeasurementTypes())
        // dispatch(getMaterials())
        dispatch(getCategories())
        dispatch(getLineItemTypes())
        dispatch(getFeeTypes())
        dispatch(getFinancingOptionTypes())
    }, [dispatch])

    useEffect(() => {
        const getQuoteAndLineItemsAsync = async () => {
            if (idAsNumber) {
                const response = await dispatch(getQuote(idAsNumber))
                setIsLoading(false)
            }
        }
        getQuoteAndLineItemsAsync()
    }, [dispatch, idAsNumber])

    const quote = useSelector((state) => state.quotes.quotes.find((item) => item.id === idAsNumber))
    const allLineItemTypes = useSelector((state) => state.lineItemTypes.lineItemTypes)
    const categoriesById = useSelector((state) => state.categories.categories.byId)
    const categories = Object.values(categoriesById)
    const feeTypes = useSelector((state) => state.feeTypes.feeTypes)
    const financingOptionTypes = useSelector(
        (state) => state.financingOptionTypes.financingOptionTypes
    )
    const fees = useSelector((state) => state.quotes.feesByQuoteId[idAsNumber]) || []
    const lineItems = useSelector((state) => state.quotes.lineItemsByQuoteId[idAsNumber]) || []
    const financingOptions = useSelector(
        (state) => state.quotes.financingOptionsByQuoteId[idAsNumber]
    )
    const sections = useSelector((state) => state.quotes.sectionsByQuoteId[idAsNumber]) || []
    const lineItemsBySections: { [key: number]: LineItemType[] } = { 0: [] }
    lineItems.forEach((item) => {
        if (!lineItemsBySections[item.section_id || 0]) {
            lineItemsBySections[item.section_id || 0] = []
        }
        lineItemsBySections[item.section_id || 0].push(item)
    })

    const [lineItemsState, setLineItemsState] = useState(lineItemsBySections[0])
    const [feesState, setFeesState] = useState(fees)
    const [financingOptionsState, setFinancingOptionsState] = useState(financingOptions)

    useEffect(() => {
        setLineItemsState(lineItems)
    }, [JSON.stringify(lineItems)])

    useEffect(() => {
        setFinancingOptionsState(financingOptions)
    }, [JSON.stringify(financingOptions)])

    useEffect(() => {
        setFeesState(fees)
    }, [JSON.stringify(fees)])

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (quote === undefined) {
        return <div>Not found</div>
    }

    console.log(quote)

    const onSubmit = async (data: QuoteUpdate) => {
        try {
            const response = await dispatch(updateQuote(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Quote updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const categoriesForLineItems = getAllCategoryChildren(quote.category_id, categories)
    if (quote.category_id !== null && categoriesById[quote.category_id]) {
        categoriesForLineItems.push(categoriesById[quote.category_id])
    }
    const categoriesForLineItemsIds = categoriesForLineItems.map((item) => item.id)
    const lineItemTypes = allLineItemTypes.filter((item) =>
        // @ts-ignore
        categoriesForLineItemsIds.includes(item?.category?.id)
    )

    const handleDragEnd = async (result: DropResult) => {
        if (!result.destination) return
        if (result.destination.droppableId === "line-items") {
            handleDragEndLineItems(result)
        }
        if (result.destination.droppableId === "fees") {
            handleDragEndFees(result)
        }
    }

    const handleDragEndFees = async (result: DropResult) => {
        if (!result.destination) return
        const reorderedItems = [...feesState]
        const [removed] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, removed)

        setFeesState(reorderedItems)

        const newOrder: { [key: number]: number } = {}
        reorderedItems.forEach((item, index) => {
            newOrder[item.id] = index
        })

        const response = await dispatch(reorderQuoteFees(idAsNumber, newOrder))
        if (response.status === 200) {
            enqueueSnackbar("Reordered successfully", { variant: "success" })
        }
    }

    const handleDragEndLineItems = async (result: DropResult) => {
        if (!result.destination) return
        const reorderedItems = [...lineItemsState]
        const [removed] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, removed)

        setLineItemsState(reorderedItems)

        const newOrder: { [key: number]: number } = {}
        reorderedItems.forEach((item, index) => {
            newOrder[item.id] = index
        })

        const response = await dispatch(reorderQuoteLineItems(idAsNumber, newOrder))
        if (response.status === 200) {
            enqueueSnackbar("Reordered successfully", { variant: "success" })
        }
    }

    // const onCreateQuoteSection = async () => {
    //     if (!quoteSectionName || isSending) return
    //     setIsSending(true)

    //     let order = 0
    //     if (sections.length > 0) {
    //         order = Math.max(...sections.map((item) => item.order)) + 1
    //     }

    //     const response = await dispatch(
    //         createQuoteSection({
    //             quote_id: idAsNumber,
    //             name: quoteSectionName,
    //             order,
    //         })
    //     )
    //     if (response.status === 201) {
    //         setQuoteSectionName("")
    //         enqueueSnackbar(`Quote section ${quoteSectionName} created`, { variant: "success" })
    //     }

    //     setIsSending(false)
    // }

    const onCreateFee = async (feeType: FeeTypeType) => {
        if (!feeType.id) return
        const response = await dispatch(addFeeToQuote(quote.id, feeType.id))
        if (response.status === 201) {
            enqueueSnackbar(`Fee ${feeType.name} added`, {
                variant: "success",
            })
        }
    }

    const onDeleteFee = async (fee: FeeType) => {
        if (!window.confirm("Are you sure you want to delete this fee?")) return
        const response = await dispatch(deleteFee(fee))
        if (response.status === 204) {
            enqueueSnackbar(`Fee ${fee.name} deleted`, {
                variant: "success",
            })
        }
    }

    const onCreateFinancingOption = async (item: TFinancingOptionType) => {
        if (!item.id) return
        const response = await dispatch(addFinancingOptionToQuote(quote.id, item.id))
        if (response.status === 201) {
            enqueueSnackbar(`Financing option ${item.name} added`, {
                variant: "success",
            })
        }
    }

    const onDeleteFinancingOption = async (item: TFinancingOption) => {
        if (!window.confirm("Are you sure you want to delete this financing option?")) return
        const response = await dispatch(deleteFinancingOption(item))
        if (response.status === 204) {
            enqueueSnackbar(`Fee ${item.name} deleted`, {
                variant: "success",
            })
        }
    }

    const onUpdateFinancingOption = async (
        item: TFinancingOption,
        changes: TFinancingOptionUpdate
    ) => {
        const response = await dispatch(updateFinancingOption(item.id, changes))
        if (response.status === 200) {
            enqueueSnackbar(`Financing option updated`, {
                variant: "success",
            })
        } else {
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
    }

    const onCreateLineItem = async (
        lineItemType: LineItemTypeType | null,
        section_id: number | null = null
    ) => {
        if (!idAsNumber || !lineItemType) return
        setIsSending(true)
        console.log("Service Type:", { lineItemType, section_id })
        let order = 0
        if (lineItems.length > 0) {
            order = Math.max(...lineItems.map((item) => item.order)) + 1
        }
        const response = await dispatch(
            createLineItem({
                quote_id: idAsNumber,
                type_id: lineItemType.id,
                order,
                section_id,
            })
        )
        if (response.status === 201) {
            setIsSending(false)
            setLineItemTypeToCreate(null)
            enqueueSnackbar(`Service ${lineItemType.name} created`, { variant: "success" })
        } else {
            setIsSending(false)
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
    }

    return (
        <>
            <Helmet>
                <title>Update Quote</title>
            </Helmet>
            <Container>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <CustomBreadcrumbs
                        heading="Update Quote"
                        links={[
                            // {
                            //     name: "Quotes",
                            // },
                            {
                                name: "Quotes",
                                href: PATHS.quotes.quotes.list(),
                            },
                            {
                                name: "Edit",
                            },
                        ]}
                        action={
                            <ButtonGroup variant="contained" size="small">
                                <Button
                                    onClick={() => {
                                        navigate(PATHS.quotes.quotes.view(idAsNumber))
                                    }}
                                >
                                    View
                                </Button>
                                {hasPermission(Permissions.QUOTE_EXPORT) && (
                                    <Button
                                        onClick={() => {
                                            navigate(PATHS.quotes.quotes.export(idAsNumber))
                                        }}
                                    >
                                        Export
                                    </Button>
                                )}
                            </ButtonGroup>
                        }
                    />
                    <QuoteEditForm onSubmit={onSubmit} initialData={quote} />
                    {!quote.sf_estimate ? (
                        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                            Please select an estimate before recording measurements.
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                                Services
                            </Typography>
                            <Stack direction="column" spacing={0}>
                                {sections.map((section) => (
                                    <QuoteSectionDetails
                                        lineItemTypes={lineItemTypes}
                                        section={section}
                                        lineItems={lineItemsBySections[section.id] || []}
                                        onCreateLineItem={onCreateLineItem}
                                    />
                                ))}

                                {/* <AccordionDetails style={{ backgroundColor: "gray" }}> */}
                                <Droppable droppableId="line-items">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {lineItemsState.map((item) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id.toString()}
                                                    index={item.order}
                                                >
                                                    {(pr) => (
                                                        <div
                                                            ref={pr.innerRef}
                                                            {...pr.draggableProps}
                                                            {...pr.dragHandleProps}
                                                        >
                                                            <LineItemDetails
                                                                key={item.id}
                                                                lineItem={item}
                                                                expanded={
                                                                    expandedLineItemId === item.id
                                                                }
                                                                setExpanded={(value) => {
                                                                    // setExpandedLineItemId(null)
                                                                    setExpandedLineItemId(value)
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                                {/* </AccordionDetails> */}
                                <Stack
                                    direction={{ xs: "column", md: "row" }}
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ mb: 0, mt: 2 }}
                                >
                                    <Autocomplete
                                        id="possible-line-items"
                                        getOptionLabel={(option) => option.name}
                                        onChange={async (
                                            event: any,
                                            value: LineItemTypeType | null
                                        ) => {
                                            setLineItemTypeToCreate(value)
                                            onCreateLineItem(value)
                                        }}
                                        value={lineItemTypeToCreate}
                                        disabled={isSending}
                                        options={lineItemTypes}
                                        sx={{ width: "100%" }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                // size="small"
                                                label="Add a Service"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    // endAdornment: (
                                                    //     <InputAdornment position="end">
                                                    //         <Button
                                                    //             variant="contained"
                                                    //             size="small"
                                                    //             sx={{ ml: 1 }}
                                                    //             onClick={() =>
                                                    //                 onCreateLineItem(lineItemTypeToCreate)
                                                    //             }
                                                    //             disabled={isSending}
                                                    //         >
                                                    //             Add
                                                    //         </Button>
                                                    //     </InputAdornment>
                                                    // ),
                                                }}
                                            />
                                        )}
                                    />
                                    {/* <TextField
                            label="Create Section"
                            variant="outlined"
                            // size="small"
                            sx={{ width: "100%" }}
                            value={quoteSectionName}
                            onChange={(e) => setQuoteSectionName(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{ ml: 1 }}
                                            onClick={onCreateQuoteSection}
                                            disabled={isSending}
                                        >
                                            Add
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        /> */}
                                </Stack>
                            </Stack>

                            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                                Fees{" "}
                                <IconButton
                                    size="small"
                                    color="default"
                                    sx={{ mt: -1 }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleOpenMenu(e)
                                        setFeesMenuOpen(true)
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                                <Menu
                                    id="add-fees-menu"
                                    anchorEl={anchorEl}
                                    open={feesMenuOpen}
                                    onClose={handleCloseMenu}
                                >
                                    {feeTypes.map((feeType) => (
                                        <MenuItem
                                            key={feeType.id}
                                            onClick={(e) => {
                                                onCreateFee(feeType)
                                                console.log(feeType)
                                                handleCloseMenu()
                                            }}
                                        >
                                            {feeType.name}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Typography>
                            <Stack direction="column" spacing={0}>
                                <Card>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Description</TableCell>
                                                    <TableCell>Value</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {feesState.map((fee) => (
                                                    <TableRow hover key={fee.id}>
                                                        <TableCell>{fee.name}</TableCell>
                                                        <TableCell>{fee.description}</TableCell>
                                                        <TableCell>{fee.value}</TableCell>
                                                        <TableCell>
                                                            <Button
                                                                size="small"
                                                                color="error"
                                                                variant="outlined"
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    onDeleteFee(fee)
                                                                }}
                                                                disabled={
                                                                    fee.data.restrictions
                                                                        ?.can_be_deleted === false
                                                                }
                                                            >
                                                                Delete
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Stack>
                            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                                Financing Options{" "}
                                {
                                    // Allow adding financing options only if there are none
                                    financingOptionsState.length === 0 && (
                                        <>
                                            <IconButton
                                                size="small"
                                                color="default"
                                                sx={{ mt: -1 }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleOpenMenu(e)
                                                    setFinancingOptionsMenuOpen(true)
                                                }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                            <Menu
                                                id="add-financing-option-menu"
                                                anchorEl={anchorEl}
                                                open={financingOptionsMenuOpen}
                                                onClose={handleCloseMenu}
                                            >
                                                {financingOptionTypes.map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        onClick={(e) => {
                                                            onCreateFinancingOption(item)
                                                            handleCloseMenu()
                                                        }}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                    )
                                }
                            </Typography>
                            <Stack direction="column" spacing={0}>
                                <Card>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {/* <TableCell>Name</TableCell>
                                                    <TableCell>Description</TableCell> */}
                                                    <TableCell>Min Amount</TableCell>
                                                    <TableCell>APR (%)</TableCell>
                                                    <TableCell>Term (Month)</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {financingOptionsState.map((item) => (
                                                    <TableRow hover key={item.id}>
                                                        {/* <TableCell>{item.name}</TableCell>
                                                        <TableCell>{item.description}</TableCell> */}
                                                        <TableCell>
                                                            {`$${item.min_amount.toLocaleString()}`}
                                                            {/* <TextFieldWithSave
                                                                initialValue={item.min_amount}
                                                                onSave={(value) =>
                                                                    onUpdateFinancingOption(item, {
                                                                        min_amount: Number(value),
                                                                    })
                                                                }
                                                            /> */}
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextFieldWithSave
                                                                initialValue={item.apr}
                                                                onSave={(value) =>
                                                                    onUpdateFinancingOption(item, {
                                                                        apr: Number(value),
                                                                    })
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextFieldWithSave
                                                                initialValue={item.term}
                                                                onSave={(value) =>
                                                                    onUpdateFinancingOption(item, {
                                                                        term: Number(value),
                                                                    })
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                size="small"
                                                                color="error"
                                                                variant="outlined"
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    onDeleteFinancingOption(item)
                                                                }}
                                                                disabled={
                                                                    !hasPermission(
                                                                        Permissions.FINANCING_OPTION_DELETE
                                                                    )
                                                                }
                                                            >
                                                                Delete
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Stack>
                            <div>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ mt: 3 }}
                                    onClick={() => navigate(PATHS.quotes.quotes.view(idAsNumber))}
                                >
                                    Done, go to View
                                </Button>
                            </div>
                        </>
                    )}
                </DragDropContext>
                {hasPermission(Permissions.ADMIN) && (
                    <Stack direction="column">
                        <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                            Admin
                        </Typography>
                        <div>
                            <Button
                                onClick={async () => {
                                    apiRecalculateMaterialsQuantity(quote.id)
                                }}
                            >
                                Recalculate materials quantity
                            </Button>
                        </div>
                    </Stack>
                )}
            </Container>
        </>
    )
}
