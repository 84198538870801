import { QuoteSectionCreate, QuoteSectionUpdate } from "src/@types/quote-section"
import axios from "../utils/axios"

const apiFetchQuoteSections = () => axios.get("/api/v1/quotes/quote-sections/")

const apiFetchQuoteSection = (id: number) => axios.get(`/api/v1/quotes/quote-sections/${id}/`)

const apiUpdateQuoteSection = (id: number, changes: QuoteSectionUpdate) =>
    axios.patch(`/api/v1/quotes/quote-sections/${id}/`, changes)

const apiCreateQuoteSection = async (payload: QuoteSectionCreate) => {
    try {
        return await axios.post(`/api/v1/quotes/quote-sections/`, payload)
    } catch (error) {
        console.log("Error:", error)
        return error.response
    }
}

const apiDeleteQuoteSection = (id: number) => axios.delete(`/api/v1/quotes/quote-sections/${id}/`)

export {
    apiUpdateQuoteSection,
    apiFetchQuoteSection,
    apiFetchQuoteSections,
    apiCreateQuoteSection,
    apiDeleteQuoteSection,
}
