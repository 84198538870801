import { createSlice, Dispatch } from "@reduxjs/toolkit"
import { GroupCreate, GroupsState, GroupUpdate } from "src/@types/security"
import {
    apiFetchGroups,
    apiCreateGroup,
    apiDeleteGroup,
    apiUpdateGroup,
    apiFetchGroup,
} from "src/api/groups"
import { apiFetchUsers } from "src/api/users"
import { apiFetchPermissions } from "src/api/permissions"

const initialState: GroupsState = {
    groups: [],
    isLoading: false,
    users: [],
    permissions: [],
}

const slice = createSlice({
    name: "groups",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        fetchGroupsSuccess: (state, action) => {
            state.isLoading = false
            state.groups = action.payload
        },
        fetchGroupSuccess: (state, action) => {
            state.isLoading = false
            const group = action.payload
            const index = state.groups.findIndex((item) => item.id === group.id)
            if (index !== -1) {
                state.groups[index] = group
            } else {
                state.groups.push(group)
            }
        },
        deleteGroupSuccess: (state, action) => {
            state.isLoading = false
            state.groups = state.groups.filter((item) => item.id !== action.payload)
        },
        fetchUsersSuccess: (state, action) => {
            state.isLoading = false
            state.users = action.payload
        },
        fetchPermissionsSuccess: (state, action) => {
            state.isLoading = false
            state.permissions = action.payload
        },
    },
})

export function getGroups() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchGroups()
        if (response.status === 200) {
            dispatch(slice.actions.fetchGroupsSuccess(response.data.results))
        }
        return response
    }
}

export function getGroup(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchGroup(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchGroupSuccess(response.data))
        }
        return response
    }
}

export function createGroup(data: GroupCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateGroup(data)
        if (response.status === 201) {
            dispatch(slice.actions.fetchGroupSuccess(response.data))
        }
        return response
    }
}

export function updateGroup(id: number, data: GroupUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateGroup(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchGroupSuccess(response.data))
        }
        return response
    }
}

export function deleteGroup(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteGroup(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteGroupSuccess(id))
        }
        return response
    }
}

export function getUsers() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchUsers()
        if (response.status === 200) {
            dispatch(slice.actions.fetchUsersSuccess(response.data.results))
        }
        return response
    }
}

export function getPermissions() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchPermissions()
        if (response.status === 200) {
            dispatch(slice.actions.fetchPermissionsSuccess(response.data.results))
        }
        return response
    }
}

export default slice.reducer
