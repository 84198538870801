import {
    Card,
    CircularProgress,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import MaterialForm from "src/forms/material-edit"

import { getMaterialType, updateMaterialType } from "src/redux/slices/material-types"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { useSnackbar } from "notistack"
import MaterialPriceRow from "./MaterialTypePriceRow"

export default function MaterialTypeDetailsPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const getMaterialTypeAsync = async () => {
            if (idAsNumber) {
                await dispatch(getMaterialType(idAsNumber))
                setIsLoading(false)
            }
        }
        getMaterialTypeAsync()
    }, [dispatch, idAsNumber])

    const materialType = useSelector((state) =>
        state.materialTypes.materialTypes.find((item) => item.id === idAsNumber)
    )
    const prices =
        useSelector((state) => state.materialTypes.pricesByMaterialTypeId[idAsNumber]) || []

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (materialType === undefined) {
        return <div>Not found</div>
    }

    const onSubmit = async (data: any) => {
        try {
            console.log(data)
            const response = await dispatch(updateMaterialType(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Material updated successfully", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Material {materialType.name}</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading={`Material Type ${materialType.name}`}
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Material Types",
                            href: PATHS.quotes.materialTypes.list(),
                        },
                        {
                            name: materialType.name,
                        },
                    ]}
                />
                <MaterialForm initialData={materialType} onSubmit={onSubmit} />
                <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Prices
                </Typography>
                <Card sx={{ maxWidth: "420px" }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell size="small" align="center">
                                        Starts From ({materialType.price_unit})
                                    </TableCell>
                                    <TableCell size="small" align="center">
                                        Price per Unit
                                    </TableCell>
                                    <TableCell size="small" align="center">
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...prices]
                                    .sort((a, b) => a.range_lower_bound - b.range_lower_bound)
                                    .map((price) => (
                                        <MaterialPriceRow
                                            key={price.id}
                                            price={price}
                                            material={materialType}
                                        />
                                    ))}
                                {/* <AddMaterialPriceRow material={material} /> */}
                                <MaterialPriceRow material={materialType} price={null} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
