import { Helmet } from 'react-helmet-async';
// @mui
import {
    Button,
    Card,
    Checkbox,
    Container,
    Dialog, DialogActions,
    DialogContent, DialogTitle, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TextField, Typography
} from '@mui/material';
// components
// import { apiFetchPayrollRuns } from 'src/api/payroll';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs';
import { PATHS } from 'src/routes/paths';
// import Iconify from 'src/components/iconify/Iconify';
import { FetchJobFilters } from 'src/api/data';
import { DataGrid, GridColDef, GridValueGetterParams, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
// import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { useDispatch, useSelector } from 'src/redux/store';
import { getJobs } from 'src/redux/slices/jobs';
import { Job, JobVisit } from 'src/@types/jobs';
import { addJobsToPayrollRun, getPayrollRuns } from 'src/redux/slices/payrollRuns';
import { useSnackbar } from 'notistack';
import CircularProgress from 'src/components/circular-progress';
import { useNavigate } from 'react-router';
import { DatePicker } from '@mui/x-date-pickers';
import { useSettingsContext } from '../../../../components/settings'
// import LoadingScreen from 'src/components/loading-screen/LoadingScreen';


const checkVisit = (
    visit: JobVisit,
    type: string[] | undefined,
    dateFrom: string | null | undefined,
    dateTo: string | null | undefined
) => {
    console.log({ visit, type, dateFrom, dateTo })

    if (type && type.length > 0) {
        if (!type.includes(visit.type)) return false
    }

    if (dateFrom) {
        if (!visit.date || visit.date < dateFrom) return false
    }

    if (dateTo) {
        if (!visit.date || visit.date > dateTo) return false
    }

    return true
}


// const applyFilters = (job: Job, filters: FetchJobFilters) => {
//     // console.log({filters})

//     if (filters.status && filters.status.length > 0) {
//         if (!filters.status.includes(job.status)) {
//             return false
//         }
//     }

//     if (filters.category && filters.category.length > 0) {
//         if (!filters.category.includes(job.category)) {
//             return false
//         }
//     }

//     if (filters.visitType || filters.visitFrom || filters.visitTo) {
//         let hasVisit = false
//         if (job.visits.length === 0) return false
//         job.visits.forEach(visit => {
//             if (checkVisit(visit, filters.visitType, filters.visitFrom, filters.visitTo)) {
//                 console.log(visit)
//                 hasVisit = true
//             }
//         })
//         if (!hasVisit) return false
//     }


//     return true
// }



const getSortModels = (field: string): GridSortModel => {
    if (!field) return []
    const order = field.startsWith('-') ? 'desc' : 'asc'
    return [{ field: field.replace('-', ''), sort: order }]
}


const JOB_STATUSES = [
    'Invoiced',
    'Completed',
    'Cancelled',
    'Confirmed',
    'Scheduled',
    'Schedule Requested',
    'Unscheduled',
    'Job Closed',
    'Archived',
]


const JOB_CATEGORIES = [
    'Commercial',
    'Residential',
    'Holiday Lights',
]


const VISIT_TYPES = [
    'regular', 'cb', 'ucb'
]


const ORDER_BY_OPTIONS = [
    // { label: 'ID ↑', value: 'id' },
    // { label: 'ID ↓', value: '-id' },
    { label: 'Start Date ↑', value: 'start_date' },
    { label: 'Start Date ↓', value: '-start_date' },
    { label: 'Updated At ↑', value: 'external_updated_at' },
    { label: 'Updated At ↓', value: '-external_updated_at' },
]


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const COLUMNS: GridColDef[] = [
    { field: 'id', headerName: 'ID', sortable: false, width: 80 },
    { field: 'number', headerName: 'Number', sortable: false },
    { field: 'start_date', headerName: 'Start Date', sortable: false },
    {
        field: 'customer_name', headerName: 'Customer Name',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.customer?.name || '', sortable: false
    },
    { field: 'category', headerName: 'Category', sortable: false, width: 120 },
    { field: 'payment_type', headerName: 'Pay Type', sortable: false },
    { field: 'status', headerName: 'Status', sortable: false },
    { field: 'total', headerName: 'Total', sortable: false, type: 'number' },
    { field: 'upsell', headerName: 'Upsell', sortable: false, type: 'number' },
    { field: 'expenses', headerName: 'Costs', sortable: false, type: 'number' },
    {
        field: 'external_updated_at',
        headerName: 'Updated At',
        valueFormatter(params) {
            return new Date(params.value as string).toLocaleString()
        },
        sortable: false,
        width: 200
    },
]


function DialogAddJobsToPayrollRun({ open, onClose, jobs }: any) {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()


    const [selectedPayrollRun, setSelectedPayrollRun] = useState<any>(null)
    const handleChange = (event: SelectChangeEvent) => {
        console.log(event.target.value)
        setSelectedPayrollRun(event.target.value)
    }

    const dispatch = useDispatch()

    const runs = useSelector((state) => state.payrollRuns.payrollRuns)

    useEffect(() => {
        dispatch(getPayrollRuns())
    }, [dispatch])

    // const runs = query.data?.data?.results || []

    // console.log(runs)

    const onSubmit = async () => {
        if (!selectedPayrollRun) return
        await dispatch(addJobsToPayrollRun(selectedPayrollRun, jobs))
        enqueueSnackbar('Jobs added to Payroll Run', {
            variant: 'success',
            onClick: () => {
                navigate(PATHS.payroll.runs.edit(selectedPayrollRun))
            },
        })
        // console.log('submit', selectedPayrollRun)
        // apiAddJobsToPayrollRun(selectedPayrollRun, jobs)
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Jobs to Payroll Run</DialogTitle>
            <DialogContent>
                <Typography>Add {jobs.length} jobs to Payroll</Typography>
                <Select
                    onChange={handleChange}
                    value={selectedPayrollRun}
                    fullWidth
                // label="Payroll Run"
                // variant="outlined"
                // input={<OutlinedInput label="Payroll Run" />}
                >
                    {runs.map((run: any) => (
                        <MenuItem key={run.id} value={run.id}>ID {run.id}: from {run.date_from} to {run.date_to}</MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" onClick={onSubmit} disabled={!selectedPayrollRun}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}



export default function JobsList() {
    const { themeStretch } = useSettingsContext()

    const [selectedRows, setSelectedRows] = useState<any>([])

    const [selectedStatuses, setSelectedStatuses] = useState<any>([])
    const [selectedCategories, setSelectedCategories] = useState<any>([])
    const [selectedVisitTypes, setSelectedVisitTypes] = useState<any>([])
    const [visitFrom, setVisitFrom] = useState<any>(null)
    const [visitTo, setVisitTo] = useState<any>(null)
    const [orderBy, setOrderBy] = useState<string>("-external_updated_at")
    const [search, setSearch] = useState<string>("")

    const [activeFilters, setActiveFilters] = useState<any>({
        status: [],
        orderBy: "-external_updated_at",
        category: [],
        visit_type: [],
        visit_from: null,
        visit_to: null,
        search: "",
    })
    const [openAddJobsToPayrollRun, setOpenAddJobsToPayrollRun] = useState(false)

    const { jobs: { byId: jobsMap }, isLoading } = useSelector((state) => state.jobs)

    const dispatch = useDispatch()

    const [jobs, setJobs] = useState<Job[]>([])
    // const jobs = Object.values(jobsMap).filter((job) => applyFilters(job, activeFilters))

    // useEffect(() => {
    //     dispatch(getJobs(activeFilters))
    // }, [dispatch, activeFilters])

    const handleChangeStatusFilter = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event
        console.log(value)
        setSelectedStatuses(
            typeof value === 'string' ? value.split(',') : value,
        )
    }

    const handleChangeCategoryFilter = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event
        console.log(value)
        setSelectedCategories(
            typeof value === 'string' ? value.split(',') : value,
        )
    }

    // const handleChangeVisitTypeFilter = (event: SelectChangeEvent<string>) => {
    //     const {
    //         target: { value },
    //     } = event
    //     console.log(value)
    //     setSelectedVisitTypes(
    //         typeof value === 'string' ? value.split(',') : value,
    //     )
    // }

    const handleChangeOrder = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event
        console.log(value)
        setOrderBy(
            value,
        )
    }

    return (
        <>
            <Helmet>
                <title>Jobs</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'}>
                <CustomBreadcrumbs
                    heading="Jobs"
                    links={[
                        { name: 'Data' },
                        {
                            name: 'Jobs',
                            href: PATHS.data.jobs.list,
                        },
                        { name: 'List' },
                    ]}
                    action={
                        <Button
                            // component={RouterLink}
                            // to={PATHS.payroll.runs.create}
                            variant="contained"
                            disabled={selectedRows.length === 0}
                            onClick={() => setOpenAddJobsToPayrollRun(true)}
                        // startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                            Add To Payroll Run
                        </Button>
                    }
                />

                <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                    <FormControl sx={{ width: 300 }} size='small'>
                        <InputLabel id="job-status-select-label">Jobs Status</InputLabel>
                        <Select
                            labelId="job-status-select-label"
                            id="job-status-select"
                            multiple
                            value={selectedStatuses}
                            onChange={handleChangeStatusFilter}
                            input={<OutlinedInput size='small' label="Jobs Status" />}
                            renderValue={(selected: any) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {JOB_STATUSES.map((status) => (
                                <MenuItem key={status} value={status}>
                                    <Checkbox checked={selectedStatuses.indexOf(status) > -1} />
                                    <ListItemText primary={status} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: 300 }} size='small'>
                        <InputLabel id="category-select-label">Job Category</InputLabel>
                        <Select
                            labelId="category-select-label"
                            id="category-select"
                            multiple
                            value={selectedCategories}
                            onChange={handleChangeCategoryFilter}
                            input={<OutlinedInput size='small' label="Job Category" />}
                            renderValue={(selected: any) => selected.join(', ')}
                            MenuProps={MenuProps}
                        // size='small'

                        >
                            {JOB_CATEGORIES.map((status) => (
                                <MenuItem key={status} value={status}>
                                    <Checkbox checked={selectedCategories.indexOf(status) > -1} />
                                    <ListItemText primary={status} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: 300 }} size="small">
                        <InputLabel id="demo-simple-select-label">Order By</InputLabel>
                        <Select
                            fullWidth
                            input={<OutlinedInput label="Order By" />}
                            onChange={handleChangeOrder}
                            value={orderBy}
                        >
                            {ORDER_BY_OPTIONS.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                    {/* <FormControl sx={{ width: 300 }}>
                        <InputLabel id="visit-type-select-label">Visit Type</InputLabel>
                        <Select
                            labelId="visit-type-select-label"
                            id="visit-type-select"
                            multiple
                            value={selectedVisitTypes}
                            onChange={handleChangeVisitTypeFilter}
                            input={<OutlinedInput label="Visit Type" />}
                            renderValue={(selected: any) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {VISIT_TYPES.map((status) => (
                                <MenuItem key={status} value={status}>
                                    <Checkbox checked={selectedVisitTypes.indexOf(status) > -1} />
                                    <ListItemText primary={status} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                    <DatePicker
                        label="Visit From"
                        // size='small'
                        value={visitFrom}
                        onChange={(newValue) => {
                            setVisitFrom(newValue)
                        }}
                        // @ts-ignore
                        renderInput={(params: any) => <TextField size='small' {...params} />}
                    />

                    <DatePicker
                        label="Visit To"
                        value={visitTo}
                        onChange={(newValue) => {
                            setVisitTo(newValue)
                        }}
                        // @ts-ignore
                        renderInput={(params: any) => <TextField {...params} size='small'  />}
                    />
                    <TextField
                        label="Job Number or Cust. Name"
                        value={search}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        // size='small'
                    />
                    <Button
                        onClick={async () => {
                            setActiveFilters({
                                status: selectedStatuses,
                                orderBy,
                                category: selectedCategories,
                                visitType: selectedVisitTypes,
                                visitFrom: visitFrom && visitFrom.toISOString().split('T')[0],
                                visitTo: visitTo && visitTo.toISOString().split('T')[0],
                                search,
                            })
                            const result = await dispatch(getJobs({
                                status: selectedStatuses,
                                orderBy,
                                category: selectedCategories,
                                visitType: selectedVisitTypes,
                                visitFrom: visitFrom && visitFrom.toISOString().split('T')[0],
                                visitTo: visitTo && visitTo.toISOString().split('T')[0],
                                search,
                            }))
                            setJobs(result.jobs)
                        }
                        }
                        disabled={isLoading}
                        variant="contained"
                    >
                        Search
                    </Button>
                    <Button
                        onClick={async () => {
                            setJobs([])
                            setActiveFilters(
                                {
                                    status: [],
                                    orderBy: "-external_updated_at",
                                    category: [],
                                    visit_type: [],
                                    visit_from: null,
                                    visit_to: null,
                                    search: "",
                                }
                            )
                            setSelectedStatuses([])
                            setSelectedCategories([])
                            setSelectedVisitTypes([])
                            setVisitFrom(null)
                            setVisitTo(null)
                            setSearch("")
                        }
                        }
                        disabled={isLoading}
                        variant="contained"
                        color='error'
                    >
                        Clear
                    </Button>
                            

                </Stack>
                {/* <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                    
                </Stack> */}

                {isLoading &&
                    <CircularProgress />}

                {!isLoading && (

                    <DataGrid
                        autoHeight
                        disableColumnMenu
                        checkboxSelection
                        sortModel={getSortModels(activeFilters.orderBy)}
                        onRowSelectionModelChange={(rows) => {
                            setSelectedRows(rows);
                        }}
                        initialState={{

                            pagination: { paginationModel: { pageSize: 25 } },
                        }}
                        rows={jobs}
                        columns={COLUMNS}
                        disableRowSelectionOnClick
                        density='compact'
                    />

                )}
            </Container>
            <DialogAddJobsToPayrollRun open={openAddJobsToPayrollRun} onClose={() => setOpenAddJobsToPayrollRun(false)} jobs={selectedRows} />
        </>
    );
}
