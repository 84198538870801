// import { Unit } from "src/@types/units"
import * as Yup from "yup"
import { useNavigate } from "react-router"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, MenuItem, Stack } from "@mui/material"
import { RHFSelect, RHFTextField } from "src/components/hook-form"
import { PATHS } from "src/routes/paths"
import JsonEditorComponent from "src/components/json-editor"

type FormData = {
    id: number | null
    name: string
    value: number
    status: string
    effective_from: string | null
    effective_to: string | null
    type: string
    data: Object
}

type Props = {
    initialData?: FormData
    onSubmit: (data: FormData) => void
    type: string
}

export default function ContainerForm({ initialData, onSubmit, type }: Props) {
    const navigate = useNavigate()

    const defaultValues = {
        id: null,
        name: "",
        value: 0,
        status: "active",
        effective_from: null,
        effective_to: null,
        type,
        data: {},
    }

    const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        // description: Yup.string().required("Description is required"),
        value: Yup.number().required("Value is required"),
    })

    const methods = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues,
    })

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData)
        }
    }, [initialData, methods])

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        <Stack direction="column" spacing={2} flex={1}>
                            <RHFTextField name="name" label="Name" required />
                            <RHFTextField name="value" label="Value" required type="number" />
                            {/* <RHFTextField name="description" label="Description" /> */}
                        </Stack>
                        <Stack
                            direction="column"
                            spacing={2}
                            // justifyContent="space-between"
                            sx={{ mt: 2 }}
                            flex={1}
                        >
                            <JsonEditorComponent
                                value={methods.watch("data")}
                                onChange={(value) => methods.setValue("data", value)}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.quotes.coefficients.list())}
                >
                    Cancel
                </Button>
                <Button size="small" variant="contained" onClick={methods.handleSubmit(onSubmit)}>
                    {initialData === undefined ? "Create" : "Save"}
                </Button>
            </Stack>
        </FormProvider>
    )
}
