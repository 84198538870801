import {
    MeasurementTypeFieldCreate,
    MeasurementTypeFieldUpdate,
} from "src/@types/measurement-type-fields"
import axios from "../utils/axios"

const apiFetchMeasurementTypeFields = () => axios.get("/api/v1/quotes/measurement-type-fields/")

const apiFetchMeasurementTypeField = (id: number) =>
    axios.get(`/api/v1/quotes/measurement-type-fields/${id}/`)

const apiUpdateMeasurementTypeField = async (id: number, changes: MeasurementTypeFieldUpdate) => {
    try {
        return await axios.patch(`/api/v1/quotes/measurement-type-fields/${id}/`, changes)
    } catch (e) {
        return e.response
    }
}

const apiCreateMeasurementTypeField = (payload: MeasurementTypeFieldCreate) =>
    axios.post(`/api/v1/quotes/measurement-type-fields/`, payload)

const apiDeleteMeasurementTypeField = (id: number) =>
    axios.delete(`/api/v1/quotes/measurement-type-fields/${id}/`)

export {
    apiUpdateMeasurementTypeField,
    apiFetchMeasurementTypeField,
    apiFetchMeasurementTypeFields,
    apiCreateMeasurementTypeField,
    apiDeleteMeasurementTypeField,
}
