import { CircularProgress, Container } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { useSnackbar } from "notistack"
import { getFeeType, updateFeeType } from "src/redux/slices/fee-types"
import { FeeTypeUpdate } from "src/@types/fee-type"
import FeeTypeForm from "src/forms/fee-type-edit"

export default function FeeTypeDetailsPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const getFeeTypeAsync = async () => {
            if (idAsNumber) {
                await dispatch(getFeeType(idAsNumber))
                setIsLoading(false)
            }
        }
        getFeeTypeAsync()
    }, [dispatch, idAsNumber])

    const feeType = useSelector((state) =>
        state.feeTypes.feeTypes.find((item) => item.id === idAsNumber)
    )

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (feeType === undefined) {
        return <div>Not found</div>
    }

    const onSubmit = async (data: FeeTypeUpdate) => {
        try {
            console.log(data)
            const response = await dispatch(updateFeeType(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Fee type updated successfully", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Fee Type {feeType.name}</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading={`Fee Type ${feeType.name}`}
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Fee Types",
                            href: PATHS.quotes.feeTypes.list(),
                        },
                        {
                            name: feeType.name,
                        },
                    ]}
                />
                <FeeTypeForm initialData={feeType} onSubmit={onSubmit} />
            </Container>
        </>
    )
}
