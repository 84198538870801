import { Container } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import { QuoteTemplateCreate } from "src/@types/quote-template"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import QuoteTemplateEditForm from "src/forms/quote-template-edit"
import { createQuoteTemplate } from "src/redux/slices/quote-templates"
import { useDispatch } from "src/redux/store"
import { PATHS } from "src/routes/paths"

export default function QuoteTemplateCreatePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmit = async (data: QuoteTemplateCreate) => {
        try {
            const response = await dispatch(createQuoteTemplate(data))
            if (response.status === 201) {
                navigate(PATHS.quotes.quoteTemplates.details(response.data.id))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Create a Quote</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Create a Quote"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "List",
                            href: PATHS.quotes.quotes.list(),
                        },
                        {
                            name: "Create",
                        },
                    ]}
                />
                <QuoteTemplateEditForm onSubmit={onSubmit} />
            </Container>
        </>
    )
}
