import { Button, Card, CardActions, CardContent, MenuItem, Stack } from "@mui/material"
import {
    MeasurementTypeFieldType,
    MeasurementTypeFieldUpdate,
} from "src/@types/measurement-type-fields"
import { RHFTextField, RHFSelect } from "src/components/hook-form"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import FormProvider from "src/components/hook-form/FormProvider"
import { Unit } from "src/@types/units"
import { useSnackbar } from "notistack"
import { useDispatch } from "src/redux/store"
import {
    deleteMeasurementTypeField,
    updateMeasurementTypeField,
} from "src/redux/slices/measurementTypes"

type Props = {
    field: MeasurementTypeFieldType
}

export default function MeasurementTypeFieldForm({ field }: Props) {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const initialValues: MeasurementTypeFieldUpdate = {
        name: field.name,
        description: field.description,
        slug: field.slug,
        value: field.value,
        order: field.order,
        unit: field.unit,
    }

    const fieldSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        // description: Yup.string().required("Description is required"),
        order: Yup.number().required("Order is required"),
        unit: Yup.string().oneOf(Object.values(Unit)).required("Unit is required"),
        slug: Yup.string().required("Slug is required"),
        // value: Yup.number().required("Value is required"),
    })

    const methods = useForm<MeasurementTypeFieldUpdate>({
        resolver: yupResolver(fieldSchema),
        defaultValues: initialValues,
    })

    const onSubmit = async (data: MeasurementTypeFieldUpdate) => {
        const response = await dispatch(updateMeasurementTypeField(field.id, data))
        if (response.status === 200) {
            enqueueSnackbar("Field Updated", { variant: "success" })
        } else {
            enqueueSnackbar("Error updating field", { variant: "error" })
        }
    }

    const onDelete = async () => {
        if (!window.confirm("Are you sure you want to delete this field?")) return
        const response = await dispatch(deleteMeasurementTypeField(field))
        if (response.status === 204) {
            enqueueSnackbar("Field Deleted", { variant: "success" })
        }
    }

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack direction={{ xs: "column", md: "row" }} sx={{ mb: 2 }} spacing={2}>
                        <RHFTextField name="name" label="Name" required />
                        <RHFTextField name="description" label="Description" />
                    </Stack>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        <RHFTextField name="slug" label="Slug" required />
                        <RHFTextField name="value" label="Default Value" type="number" />
                        <RHFSelect name="unit" label="Base Unit" required>
                            {Object.values(Unit).map((unit) => (
                                <MenuItem key={unit} value={unit}>
                                    {unit}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Stack>
                </CardContent>
                {/* <CardActionArea> */}
                <CardActions>
                    <Button size="small" color="primary" onClick={methods.handleSubmit(onSubmit)}>
                        Save
                    </Button>
                    <Button size="small" color="error" onClick={onDelete}>
                        Delete
                    </Button>
                </CardActions>
                {/* </CardActionArea> */}
            </Card>
        </FormProvider>
    )
}
