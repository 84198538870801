import { createSlice, Dispatch } from "@reduxjs/toolkit"
import { FeeTypeCreate, FeeTypeState, FeeTypeUpdate } from "src/@types/fee-type"
import {
    apiFetchFeeTypes,
    apiCreateFeeType,
    apiDeleteFeeType,
    apiUpdateFeeType,
    apiFetchFeeType,
} from "src/api/fee-types"

const initialState: FeeTypeState = {
    feeTypes: [],
    isLoading: false,
}

const slice = createSlice({
    name: "feeTypes",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        fetchFeeTypesSuccess: (state, action) => {
            state.isLoading = false
            state.feeTypes = action.payload
        },
        fetchFeeTypeSuccess: (state, action) => {
            state.isLoading = false
            const feeType = action.payload
            const index = state.feeTypes.findIndex((item) => item.id === feeType.id)
            if (index !== -1) {
                state.feeTypes[index] = feeType
            } else {
                state.feeTypes.push(feeType)
            }
        },
        createFeeTypeSuccess: (state, action) => {
            state.isLoading = false
            state.feeTypes.push(action.payload)
        },
        deleteFeeTypeSuccess: (state, action) => {
            state.isLoading = false
            state.feeTypes = state.feeTypes.filter((item) => item.id !== action.payload)
        },
    },
})

export function getFeeTypes() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchFeeTypes()
        if (response.status === 200) {
            dispatch(slice.actions.fetchFeeTypesSuccess(response.data.results))
        }
        return response
    }
}

export function getFeeType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchFeeType(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchFeeTypeSuccess(response.data))
        }
        return response
    }
}

export function createFeeType(data: FeeTypeCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateFeeType(data)
        if (response.status === 201) {
            dispatch(slice.actions.createFeeTypeSuccess(response.data))
        }
        return response
    }
}

export function updateFeeType(id: number, data: FeeTypeUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateFeeType(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchFeeTypeSuccess(response.data))
        }
        return response
    }
}

export function deleteFeeType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteFeeType(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteFeeTypeSuccess(id))
        }
        return response
    }
}

export default slice.reducer
