import { useNavigate } from "react-router"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, Stack, Typography } from "@mui/material"
import { RHFTextField } from "src/components/hook-form"
import { PATHS } from "src/routes/paths"
import { SFEstimate } from "src/@types/sf-estimate"
import { Quote, QuoteCreate } from "src/@types/quote"
import EstimateSearchComponent from "src/components/search-estimates"
import { useDispatch } from "src/redux/store"
import { updateQuote } from "src/redux/slices/quotes"
import { useSnackbar } from "notistack"
import CategorySelectTree from "../category-select"

type QuoteFormData = {
    id: number | null
    description: string
    notes: string
    category_id: number | null
    sf_estimate: SFEstimate | null
}

type Props = {
    initialData?: Quote
    onSubmit: (data: QuoteCreate) => void
}

export default function QuoteEditForm({ onSubmit, initialData }: Props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const defaultValues = {
        id: null,
        description: "",
        notes: "",
        category_id: null,
        sf_estimate: null,
    }

    const LineItemTypeSchema = Yup.object().shape({
        // description: Yup.string().required("Description is required"),
        category_id: Yup.number().required("Category is required"),
    })

    const methods = useForm<QuoteFormData>({
        resolver: yupResolver(LineItemTypeSchema),
        defaultValues,
    })

    useEffect(() => {
        console.log("initialData", initialData)
        if (initialData) {
            methods.reset(initialData)
        }
    }, [initialData, methods])

    const handleSubmit = (data: QuoteFormData) => {
        const payload = {
            id: data.id,
            notes: data.notes,
            description: data.description,
            category_id: data.category_id,
            sf_estimate_id: data.sf_estimate?.id || null,
        }
        onSubmit(payload)
    }

    const handleEstimateChange = async (estimate: SFEstimate | null) => {
        methods.setValue("sf_estimate", estimate)
        if (initialData?.id) {
            const response = await dispatch(
                updateQuote(initialData.id, { sf_estimate_id: estimate?.id || null })
            )
            if (response.status === 200) {
                enqueueSnackbar("Estimate updated successfully", { variant: "success" })
            }
        }
    }

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        spacing={2}
                        justifyContent="space-between"
                    >
                        <Stack direction="column" spacing={2} flex={1}>
                            {/* <RHFTextField name="name" label="Name" required /> */}
                            {/* <RHFTextField name="description" label="Description" /> */}
                            <EstimateSearchComponent
                                onSelect={handleEstimateChange}
                                value={initialData?.sf_estimate}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} width={{ xs: "100%", md: "50%" }}>
                            {initialData ? (
                                <Typography variant="body1">
                                    <b>Category:</b> {initialData.category?.name || "No Category"}
                                </Typography>
                            ) : (
                                <CategorySelectTree
                                    current="none"
                                    onChange={(value) => {
                                        console.log(value)
                                        methods.setValue("category_id", value)
                                    }}
                                    disable={[]}
                                    emptyLabel="No Category"
                                />
                            )}
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            {initialData === undefined && (
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ mt: 2 }}
                    alignItems="center"
                >
                    <Button
                        size="small"
                        color="error"
                        onClick={() => navigate(PATHS.quotes.quotes.list())}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={methods.handleSubmit(handleSubmit)}
                    >
                        {initialData === undefined ? "Create" : "Save"}
                    </Button>
                </Stack>
            )}
        </FormProvider>
    )
}
