import { Category } from "src/@types/category"
import { Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useNavigate } from "react-router"
import { PATHS } from "src/routes/paths"

type CategoriesTableProps = {
    categories: Category[]
    allCategories: Category[]
}


const countChildren = (category: Category, allCategories: Category[]) => {
    const children = allCategories.filter((item) => item.parent_id === category.id)
    return children.length
}


export default function CategoriesTable({ categories, allCategories } : CategoriesTableProps) {
    const navigate = useNavigate()
    return <Card>
    <TableContainer>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Children</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    categories.map((category) => (
                        <TableRow
                            key={category.id}
                            hover
                            onClick={() => navigate(PATHS.quotes.categories.details(category.id))}
                            sx={{ cursor: 'pointer' }}
                        >
                            <TableCell>{category.id}</TableCell>
                            <TableCell>{category.name}</TableCell>
                            <TableCell>{category.description}</TableCell>
                            <TableCell>{countChildren(category, allCategories) || null}</TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => navigate(PATHS.quotes.categories.details(category.id))}
                                >
                                    Edit
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    </TableContainer>
</Card>
}