import { PackageItemType, PackageType } from "src/@types/package"
import { useDispatch } from "src/redux/store"
import { addPackageItem, deletePackageItem, updateFee } from "src/redux/slices/quotes"
import { FeeType } from "src/@types/fee"
import CheckboxCell from "./checkbox-cell"
import EditTextCell from "./edit-text-cell"
import EditNumberCell from "./edit-number-cell"

type Props = {
    fee: FeeType
    packages: PackageType[]
    packageItems: PackageItemType[]
}

export default function FeeItemRow({ fee, packages, packageItems }: Props) {
    const dispatch = useDispatch()

    const packageAlaCarte = packages[0]
    const package1 = packages[1]
    const package2 = packages[2]
    const package3 = packages[3]

    const packageAlaCarteItem =
        packageItems.filter((packageItem) => packageItem.package_id === packageAlaCarte.id)[0] ||
        null
    const package1Item =
        packageItems.filter((packageItem) => packageItem.package_id === package1.id)[0] || null
    const package2Item =
        packageItems.filter((packageItem) => packageItem.package_id === package2.id)[0] || null
    const package3Item =
        packageItems.filter((packageItem) => packageItem.package_id === package3?.id)[0] || null

    const onChangeOption = async (packageId: number, state: boolean) => {
        if (!state) {
            const packageItem = packageItems.filter((pi) => pi.package_id === packageId)[0]
            if (packageItem) {
                dispatch(deletePackageItem(packageItem))
            }
            return
        }

        const response = dispatch(
            addPackageItem({
                package_id: packageId,
                fee_id: fee.id,
            })
        )
        console.log(response)
    }

    return (
        <tr key={fee.id}>
            <CheckboxCell
                state={Boolean(package1Item)}
                onChange={(value) => {
                    onChangeOption(package1.id, value)
                }}
                bgColor="orange"
                disabled={fee.data.packages?.restrict_include_change?.includes(1)}
            />
            <CheckboxCell
                state={Boolean(package2Item)}
                onChange={(value) => {
                    onChangeOption(package2.id, value)
                }}
                bgColor="yellow"
                disabled={fee.data.packages?.restrict_include_change?.includes(2)}
            />
            <CheckboxCell
                state={Boolean(package3Item)}
                onChange={(value) => {
                    onChangeOption(package3.id, value)
                }}
                bgColor="LawnGreen"
                disabled={fee.data.packages?.restrict_include_change?.includes(3)}
            />
            <td className="text-regular">{fee.name}</td>
            <EditTextCell
                state={fee.description}
                onChange={(value) => {
                    dispatch(updateFee(fee.id, { description: value }))
                }}
                name={fee.name}
                // textStyles={{ fontSize: 8 }}
            />
            <EditNumberCell
                state={fee.value}
                onChange={(value) => {
                    dispatch(updateFee(fee.id, { value }))
                }}
                name={fee.name}
                colSpan={2}
            />
            {/* <td colSpan={2}>{fee.value}</td> */}
            <CheckboxCell
                state={Boolean(packageAlaCarteItem)}
                onChange={(value) => {
                    onChangeOption(packageAlaCarte.id, value)
                }}
                disabled={fee.data.packages?.restrict_include_change?.includes(0)}
            />
        </tr>
    )
}
