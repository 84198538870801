import { Container } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import { ContainerCreate } from "src/@types/containers"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import ContainerForm from "src/forms/container-edit"
import { createContainer } from "src/redux/slices/containers"
import { useDispatch } from "src/redux/store"
import { PATHS } from "src/routes/paths"

export default function CoefficientCreatePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmit = async (data: ContainerCreate) => {
        try {
            console.log(data)
            const response = await dispatch(createContainer(data))
            if (response.status === 201) {
                navigate(PATHS.quotes.coefficients.details(response.data.id))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Create Coefficient</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Create a Coefficient"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Coefficients",
                            href: PATHS.quotes.coefficients.list(),
                        },
                        {
                            name: "Create",
                        },
                    ]}
                />
                <ContainerForm onSubmit={onSubmit} type="coefficient" />
            </Container>
        </>
    )
}
