import { MeasurementUpdateType } from "src/@types/measurement"
import axios from "../utils/axios"

const apiUpdateMeasurement = (id: number, changes: MeasurementUpdateType) =>
    axios.patch(`/api/v1/quotes/measurements/${id}/`, changes)

const apiDeleteMeasurement = (id: number) => axios.delete(`/api/v1/quotes/measurements/${id}/`)

const apiCreateMeasurement = (lineItemId: number, measurementTypeId: number) =>
    axios.post(`/api/v1/quotes/measurements/`, {
        line_item_id: lineItemId,
        type_id: measurementTypeId,
    })

export { apiUpdateMeasurement, apiDeleteMeasurement, apiCreateMeasurement }
