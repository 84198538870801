import { MaterialType } from "src/@types/material"
import { Menu, MenuItem } from "@mui/material"
import { useState } from "react"

type MaterialOptions = 1 | 2 | null

type Props = {
    state: MaterialOptions
    bgColor?: string
    onChange: (value: MaterialOptions) => void
    materials: MaterialType[]
}

export default function ChooseProductCell({
    state,
    bgColor = "white",
    onChange,
    materials,
}: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleOpenMenu = (event: any) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    // let menuOption1: MaterialOptions = 1
    // let menuOption1Text = "White"
    // let menuOption2: MaterialOptions = 2
    // let menuOption2Text = "Dynamic"
    let stateText: string | null = null
    let menuItems: any = [
        {
            value: 1,
            text: "White",
        },
    ]
    if (materials.length === 2) {
        menuItems.push({
            value: 2,
            text: "Dynamic",
        })
    }

    if (state === 1) {
        stateText = "White"
        menuItems = []
        if (materials.length === 2) {
            menuItems.push({
                value: 2,
                text: "Dynamic",
            })
        }
        menuItems.push({
            value: null,
            text: "N/A",
        })
        // menuOption1 = 2
        // menuOption1Text = "Dynamic"
        // menuOption2 = null
        // menuOption2Text = "N/A"
    } else if (state === 2) {
        stateText = "Dynamic"
        menuItems = []
        menuItems.push({
            value: 1,
            text: "White",
        })
        menuItems.push({
            value: null,
            text: "N/A",
        })
        // menuOption1 = 1
        // menuOption1Text = "White"
        // menuOption2 = null
        // menuOption2Text = "N/A"
    }

    return (
        <>
            <td
                style={{
                    backgroundColor: bgColor,
                    width: "40px",
                    padding: "0px",
                    position: "relative",
                    backgroundClip: "padding-box",
                    margin: 0,
                }}
            >
                <div
                    role="button"
                    tabIndex={0}
                    onClick={handleOpenMenu}
                    onKeyDown={() => {}} // For accessibility
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "8px",
                    }}
                >
                    {stateText}
                </div>
            </td>
            <Menu
                id="choose-option-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                transformOrigin={{ vertical: "center", horizontal: "center" }}
            >
                {menuItems.map((item: any) => (
                    <MenuItem
                        dense
                        onClick={(e: any) => {
                            onChange(item.value)
                            handleCloseMenu()
                        }}
                    >
                        {item.text}
                    </MenuItem>
                ))}
                {/* <MenuItem
                    dense
                    onClick={(e: any) => {
                        onChange(menuOption1)
                        handleCloseMenu()
                    }}
                >
                    {menuOption1Text}
                </MenuItem>
                <MenuItem
                    dense
                    onClick={(e: any) => {
                        onChange(menuOption2)
                        handleCloseMenu()
                    }}
                >
                    {menuOption2Text}
                </MenuItem> */}
            </Menu>
        </>
    )
}
