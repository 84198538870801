import { forwardRef } from "react"
import { Link as RouterLink } from "react-router-dom"
// @mui
import { useTheme } from "@mui/material/styles"
import { Box, Link, BoxProps } from "@mui/material"

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
    disabledLink?: boolean
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
    ({ disabledLink = false, sx, ...other }, ref) => {
        const theme = useTheme()

        const PRIMARY_LIGHT = theme.palette.primary.light

        const PRIMARY_MAIN = theme.palette.primary.main

        const PRIMARY_DARK = theme.palette.primary.dark

        // OR using local (public folder)
        // -------------------------------------------------------
        const logo = (
            <Box
                component="img"
                src="/favicon/favicon_512.png"
                sx={{ width: 40, height: 40, cursor: "pointer", ...sx }}
            />
        )

        if (disabledLink) {
            return logo
        }

        return (
            <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
                {logo}
            </Link>
        )
    }
)

export default Logo
