import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getCategories } from "src/redux/slices/categories"
import { deleteLineItemType, getLineItemTypes } from "src/redux/slices/lineItemTypes"
import { useDispatch, useSelector } from "src/redux/store"
import AddIcon from "@mui/icons-material/Add"
import { PATHS } from "src/routes/paths"
import { Category } from "src/@types/category"
import { useSnackbar } from "notistack"

const getCategoriesPath = (id: number, categoriesById: { [key: number]: Category }): string[] => {
    const category = categoriesById[id]
    if (category.parent_id === null) {
        return [category.name]
    }
    return [...getCategoriesPath(category.parent_id, categoriesById), category.name]
}

export default function LineItemTypesListPage() {
    const navigate = useNavigate()

    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()
    const { lineItemTypes } = useSelector((state) => state.lineItemTypes)
    const {
        categories: { byId: categoriesById },
    } = useSelector((state) => state.categories)

    useEffect(() => {
        dispatch(getLineItemTypes())
        dispatch(getCategories())
    }, [dispatch])

    const onDelete = async (id: number) => {
        if (window.confirm("Are you sure you want to delete this Service Type?")) {
            const response = await dispatch(deleteLineItemType(id))
            if (response.status === 204) {
                enqueueSnackbar("Quote deleted successfully", { variant: "success" })
            } else {
                enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Service Types</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Service Types"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Service Types",
                            href: PATHS.quotes.lineItemTypes.list(),
                        },
                        {
                            name: "List",
                        },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(PATHS.quotes.lineItemTypes.create())}
                        >
                            Create Service Type
                        </Button>
                    }
                />
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Export Name</TableCell>
                                    <TableCell>Export Description</TableCell>
                                    <TableCell>Base Unit</TableCell>
                                    {/* <TableCell>Material Base Unit</TableCell> */}
                                    <TableCell>Category</TableCell>
                                    <TableCell>Materials</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lineItemTypes.map((lineItemType) => {
                                    const { id, name, description, category_id } = lineItemType
                                    let categoriesPath: string[] = []
                                    console.log(lineItemType)
                                    if (category_id && categoriesById[category_id]) {
                                        // const category = categoriesById[category_id]
                                        categoriesPath = getCategoriesPath(
                                            category_id,
                                            categoriesById
                                        )
                                    }
                                    return (
                                        <TableRow key={id} hover>
                                            <TableCell>{name}</TableCell>
                                            <TableCell sx={{ fontSize: 10 }}>
                                                {description}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 10 }}>
                                                {lineItemType.export_name}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 10 }}>
                                                {lineItemType.export_description}
                                            </TableCell>
                                            <TableCell>{lineItemType.base_unit}</TableCell>
                                            {/* <TableCell>{lineItemType.base_material_unit}</TableCell> */}
                                            <TableCell>{categoriesPath.join(" > ")}</TableCell>
                                            <TableCell sx={{ fontSize: 10 }}>
                                                <b>Possible:</b>{" "}
                                                {lineItemType.materials_possible
                                                    .map((m) => m.name)
                                                    .join(", ") || "-"}
                                                <br />
                                                <b>Category:</b>{" "}
                                                {lineItemType.materials_possible_categories[0]
                                                    ? getCategoriesPath(
                                                          lineItemType
                                                              .materials_possible_categories[0].id,
                                                          categoriesById
                                                      ).join(" > ")
                                                    : "-"}
                                                <br />
                                                <b>Option 1:</b>{" "}
                                                {lineItemType.material_default_op_1?.name || "-"}
                                                <br />
                                                <b>Option 2:</b>{" "}
                                                {lineItemType.material_default_op_2?.name || "-"}
                                            </TableCell>
                                            <TableCell>
                                                <ButtonGroup size="small" variant="outlined">
                                                    <Button
                                                        onClick={() =>
                                                            navigate(
                                                                PATHS.quotes.lineItemTypes.details(
                                                                    id
                                                                )
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => onDelete(id)}
                                                        color="error"
                                                    >
                                                        Delete
                                                    </Button>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
