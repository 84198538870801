import { CategoryCreate, CategoryUpdate } from "src/@types/category"
import axios from '../utils/axios'

const apiFetchCategories = () => axios.get('/api/v1/quotes/categories/')

const apiFetchCategory = (id: number) => axios.get(`/api/v1/quotes/categories/${id}/`)

const apiUpdateCategory = (id: number, changes: CategoryUpdate) => {
    const updates: any = {}
    // console.log("Changes:", changes)    
    // if (changes.name) {
    //     updates.name = changes.name
    // }
    // if (changes.description) {
    //     updates.description = changes.description
    // }
    // if (changes.parent_id !== undefined) {
    //     updates.parent = changes.parent_id
    // }
    return axios.patch(`/api/v1/quotes/categories/${id}/`, changes)
}

const apiCreateCategory = (payload: CategoryCreate) =>
    axios.post(`/api/v1/quotes/categories/`, payload)

export {
    apiUpdateCategory,
    apiFetchCategory,
    apiFetchCategories,
    apiCreateCategory,
}