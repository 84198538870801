import {
    QuoteTemplateCreate,
    QuoteTemplateItemCreate,
    QuoteTemplateUpdate,
} from "src/@types/quote-template"
import axios from "../utils/axios"

const apiFetchQuoteTemplates = () => axios.get("/api/v1/quotes/quote-templates/")

const apiFetchQuoteTemplate = (id: number) => axios.get(`/api/v1/quotes/quote-templates/${id}/`)

const apiUpdateQuoteTemplate = (id: number, changes: QuoteTemplateUpdate) =>
    axios.patch(`/api/v1/quotes/quote-templates/${id}/`, changes)

const apiCreateQuoteTemplate = (payload: QuoteTemplateCreate) =>
    axios.post(`/api/v1/quotes/quote-templates/`, payload)

const apiDeleteQuoteTemplate = (id: number) => axios.delete(`/api/v1/quotes/quote-templates/${id}/`)

const apiCreateQuoteTemplateItem = (data: QuoteTemplateItemCreate) =>
    axios.post(`/api/v1/quotes/quote-template-items/`, data)

const apiDeleteQuoteTemplateItem = (id: number) =>
    axios.delete(`/api/v1/quotes/quote-template-items/${id}/`)

const apiCreateQuoteFromTemplate = (id: number) =>
    axios.post(`/api/v1/quotes/quote-templates/${id}/create-quote/`)

const apiReorderQuoteTemplateItems = (id: number, order: { [key: number]: number }) =>
    axios.patch(`/api/v1/quotes/quote-templates/${id}/reorder/`, order)

export {
    apiFetchQuoteTemplates,
    apiFetchQuoteTemplate,
    apiUpdateQuoteTemplate,
    apiCreateQuoteTemplate,
    apiDeleteQuoteTemplate,
    apiCreateQuoteTemplateItem,
    apiDeleteQuoteTemplateItem,
    apiCreateQuoteFromTemplate,
    apiReorderQuoteTemplateItems,
}
