import { QuoteCreate, QuoteUpdate } from "src/@types/quote"
import axios from "../utils/axios"

type FetchQuotesParams = {
    page?: number
    page_size?: number
    order_by?: string
}

const apiFetchQuotes = (params: FetchQuotesParams = {}) =>
    axios.get("/api/v1/quotes/quotes/", { params })

const apiFetchQuote = (id: number) => axios.get(`/api/v1/quotes/quotes/${id}/`)

const apiUpdateQuote = (id: number, changes: QuoteUpdate) =>
    axios.patch(`/api/v1/quotes/quotes/${id}/`, changes)

const apiCreateQuote = (payload: QuoteCreate) => axios.post(`/api/v1/quotes/quotes/`, payload)

const apiDeleteQuote = (id: number) => axios.delete(`/api/v1/quotes/quotes/${id}/`)

const apiReorderQuoteLineItems = (id: number, order: { [key: number]: number }) =>
    axios.patch(`/api/v1/quotes/quotes/${id}/reorder-line-items/`, order)

const apiReorderQuoteFees = (id: number, order: { [key: number]: number }) =>
    axios.patch(`/api/v1/quotes/quotes/${id}/reorder-fees/`, order)

const apiRecalculateMaterialsQuantity = (id: number) =>
    axios.put(`/api/v1/quotes/quotes/${id}/recalculate-materials-quantity/`)

export {
    apiUpdateQuote,
    apiFetchQuote,
    apiFetchQuotes,
    apiCreateQuote,
    apiDeleteQuote,
    apiReorderQuoteLineItems,
    apiReorderQuoteFees,
    apiRecalculateMaterialsQuantity,
}
