import { useNavigate } from "react-router"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, Stack } from "@mui/material"
import { RHFTextField } from "src/components/hook-form"
import { PATHS } from "src/routes/paths"
import { useDispatch } from "src/redux/store"
import { useSnackbar } from "notistack"
import { PackageTypeCreate, PackageTypeType } from "src/@types/package-type"

type FormData = {
    id: number | null
    name: string
    description: string
    discount_percent: number
    order: number
}

type Props = {
    initialData?: PackageTypeType
    onSubmit: (data: PackageTypeCreate) => void
}

export default function PackageTypeEditForm({ onSubmit, initialData }: Props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const defaultValues = {
        id: null,
        name: "",
        description: "",
        discount_percent: 0,
        order: 0,
    }

    const schema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
    })

    const methods = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues,
    })

    useEffect(() => {
        console.log("initialData", initialData)
        if (initialData) {
            methods.reset(initialData)
        }
    }, [initialData, methods])

    const handleSubmit = (data: FormData) => {
        const payload = {
            id: data.id,
            name: data.name,
            description: data.description,
            discount_percent: data.discount_percent,
            order: data.order,
        }
        onSubmit(payload)
    }

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        spacing={2}
                        justifyContent="space-between"
                    >
                        <Stack direction="column" spacing={2} flex={1}>
                            <RHFTextField name="name" label="Name" required />
                            <RHFTextField name="description" label="Description" />
                        </Stack>
                        <Stack direction="column" spacing={2} flex={1}>
                            <RHFTextField name="discount_percent" label="Discount" required />
                            <RHFTextField name="order" label="Order" required />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.quotes.packageTypes.list())}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={methods.handleSubmit(handleSubmit)}
                >
                    {initialData === undefined ? "Create" : "Save"}
                </Button>
            </Stack>
        </FormProvider>
    )
}
