import { ContainerCreate, ContainerUpdate } from "src/@types/containers"
import axios from "../utils/axios"

const apiUpdateContainer = (id: number, changes: ContainerUpdate) =>
    axios.patch(`/api/v1/content/containers/${id}/`, changes)

const apiCreateContainer = (payload: ContainerCreate) =>
    axios.post(`/api/v1/content/containers/`, payload)

const apiDeleteContainer = (id: number) => axios.delete(`/api/v1/content/containers/${id}/`)

export type FetchContainerFilters = {
    related_entity_ids?: number[]
    related_entity_type?: string
    type?: string
}

const apiFetchContainers = (filters: FetchContainerFilters) => {
    const params: any = {}
    if (filters.related_entity_ids) {
        params.related_entity_ids = filters.related_entity_ids.join(",")
    }
    if (filters.related_entity_type) {
        params.related_entity_type = filters.related_entity_type
    }
    if (filters.type) {
        params.type = filters.type
    }

    return axios.get(`/api/v1/content/containers/`, { params })
}

const apiFetchContainer = (id: number) => axios.get(`/api/v1/content/containers/${id}/`)

export {
    apiUpdateContainer,
    apiCreateContainer,
    apiFetchContainers,
    apiDeleteContainer,
    apiFetchContainer,
}
