import { FeeType } from "src/@types/fee"
import { MaterialType } from "src/@types/material"
import { PackageItemType } from "src/@types/package"

const calculatePackageTotal = (
    packageItems: PackageItemType[],
    materialsById: { [key: number]: MaterialType },
    feesById: { [key: number]: FeeType },
    modifier: number = 0
) => {
    let total = 0
    let lineItemsCount = 0
    packageItems.forEach((packageItem) => {
        if (packageItem.material_id) {
            total += materialsById[packageItem.material_id]?.total || 0
            lineItemsCount += 1
        }
        if (packageItem.fee_id) {
            total += feesById[packageItem.fee_id]?.value || 0
        }
    })
    // Return 0 if there are no line items regardless of fees
    if (lineItemsCount === 0) return 0
    return total + modifier
}

export { calculatePackageTotal }
