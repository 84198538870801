import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material"
import { LineItemType } from "src/@types/lineItem"
import { QuoteSectionType } from "src/@types/quote-section"
import AddIcon from "@mui/icons-material/Add"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DeleteIcon from "@mui/icons-material/Delete"
import { useState } from "react"
import { LineItemTypeType } from "src/@types/lineItemType"
import { useSnackbar } from "notistack"
import { useDispatch } from "src/redux/store"
import { deleteQuoteSection } from "src/redux/slices/quotes"
import LineItemDetails from "./line-item-details"

type Props = {
    section: QuoteSectionType
    lineItems: LineItemType[]
    lineItemTypes: LineItemTypeType[]
    onCreateLineItem: (lineItemType: LineItemTypeType, sectionId: number) => void
}

export default function QuoteSectionDetails({
    section,
    lineItems,
    lineItemTypes,
    onCreateLineItem,
}: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }
    const onDelete = async () => {
        if (
            window.confirm("Are you sure you want to delete this section and all Services in it?")
        ) {
            const response = await dispatch(deleteQuoteSection(section))
            if (response.status === 204) {
                enqueueSnackbar("Service deleted successfully", { variant: "success" })
            }
        }
    }

    return (
        <Accordion key={section.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ border: "1px solid gray" }}>
                <Stack
                    direction="row"
                    spacing={0}
                    justifyContent="space-between"
                    width="100%"
                    paddingRight={2}
                >
                    <Typography variant="h6">{section.name}</Typography>
                    <div>
                        <IconButton
                            size="small"
                            color="default"
                            onClick={(e) => {
                                e.stopPropagation()
                                handleOpenMenu(e)
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            color="default"
                            onClick={(e) => {
                                e.stopPropagation()
                                onDelete()
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                    <Menu
                        id={`create-line-item-menu-${section.id}`}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                    >
                        {lineItemTypes.map((item) => (
                            <MenuItem
                                key={item.id}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onCreateLineItem(item, section.id)
                                    handleCloseMenu()
                                }}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Menu>
                </Stack>
            </AccordionSummary>
            {/* <AccordionDetails style={{ backgroundColor: "gray" }}>
                {lineItems.map((item) => (
                    <LineItemDetails key={item.id} lineItem={item} />
                ))}
            </AccordionDetails> */}
        </Accordion>
    )
}
