import { PermissionType } from "./permission"
import { User } from "./user"

export enum Permissions {
    GROUP_VIEW = "view_group",
    GROUP_UPDATE = "change_group",
    GROUP_DELETE = "delete_group",
    GROUP_CREATE = "add_group",

    USER_VIEW = "view_customuser",

    PERMISSION_VIEW = "view_permission",

    QUOTE_DELETE = "delete_quote",
    QUOTE_EXPORT = "export_quote",

    PACKAGE_TYPE_VIEW = "view_packagetype",
    PACKAGE_TYPE_CREATE = "add_packagetype",
    PACKAGE_TYPE_UPDATE = "change_packagetype",
    PACKAGE_TYPE_DELETE = "delete_packagetype",

    FINANCING_OPTION_TYPE_VIEW = "view_financingoptiontype",
    FINANCING_OPTION_TYPE_CREATE = "add_financingoptiontype",
    FINANCING_OPTION_TYPE_UPDATE = "change_financingoptiontype",
    FINANCING_OPTION_TYPE_DELETE = "delete_financingoptiontype",

    FINANCING_OPTION_DELETE = "delete_financingoption",

    BETA = "beta",
    ADMIN = "admin",
}

export type GroupType = {
    id: number
    name: string
    permissions: PermissionType[]
    users: User[]
}

export type GroupCreate = {
    name: string
}

export type GroupUpdate = {
    name?: string
    permission_ids?: number[]
    user_ids?: number[]
}

export type GroupsState = {
    groups: GroupType[]
    isLoading: boolean
    users: User[]
    permissions: PermissionType[]
}
