// import { useState } from "react"
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    // Autocomplete,
    Button,
    // Divider,
    IconButton,
    Stack,
    // TextField,
    Typography,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DeleteIcon from "@mui/icons-material/Delete"
import { useDispatch } from "src/redux/store"
import { useSnackbar } from "notistack"
import { QuoteTemplateItemType } from "src/@types/quote-template"
import { deleteQuoteTemplateItem } from "src/redux/slices/quote-templates"

type Props = {
    item: QuoteTemplateItemType
    getName?: (item: QuoteTemplateItemType) => string
}

export default function QuoteTemplateItem({ item, getName }: Props) {
    // const [description, setDescription] = useState<string>(item.description)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    let name = item.line_item_type?.name || item.fee_type?.name || item.financing_option_type?.name

    if (getName) {
        name = getName(item)
    }

    const onDelete = async () => {
        if (window.confirm(`Are you sure you want to delete this template item ${name}?`)) {
            const response = await dispatch(deleteQuoteTemplateItem(item))
            if (response.status === 204) {
                enqueueSnackbar(`Item ${name} deleted from template successfully`, {
                    variant: "success",
                })
            } else {
                enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
            }
        }
    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{ backgroundColor: "white" }}
                sx={{ borderTop: "1px solid #DDD" }}
            >
                <Stack
                    direction="row"
                    spacing={0}
                    justifyContent="space-between"
                    width="100%"
                    paddingRight={2}
                >
                    <Typography>
                        <b>{name}</b>
                    </Typography>
                    <IconButton
                        size="small"
                        color="default"
                        onClick={(e) => {
                            e.stopPropagation()
                            onDelete()
                        }}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                {/* <FormProvider methods={methods} onSubmit={() => { }}> */}
                {/* <Stack direction="column" spacing={2}> */}
                {/* <TextField
                        id="description"
                        label="Description"
                        // multiline
                        // rows={2}
                        fullWidth
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        onBlur={onChangeDescription}
                    /> */}
                {/* <Divider color="disabled" /> */}

                {/* <Typography variant="subtitle1">Material</Typography> */}
                {/* </Stack>
                <Stack direction="row" spacing={1} sx={{ mt: 2 }}> */}

                {/* </Stack> */}
            </AccordionDetails>
            <AccordionActions>
                {/* <Button size="small" color="primary" onClick={onAddMeasurement}>
                    Add Measurement
                </Button> */}
                <Button size="small" color="error" onClick={onDelete}>
                    Delete Item
                </Button>
            </AccordionActions>
        </Accordion>
        // </FormProvider>
    )
}
