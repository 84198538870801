import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import AddIcon from "@mui/icons-material/Add"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { deleteGroup, getGroups } from "src/redux/slices/groups"
import { GroupType, Permissions } from "src/@types/security"
import { useAuthContext } from "src/auth/useAuthContext"

export default function GroupsListPage() {
    const dispatch = useDispatch()
    const { groups } = useSelector((state) => state.groups)

    const { hasPermission } = useAuthContext()

    useEffect(() => {
        dispatch(getGroups())
    }, [dispatch])

    const onDelete = async (group: GroupType) => {
        if (!window.confirm(`Are you sure you want to delete the group ${group.name}?`)) return
        const response = await dispatch(deleteGroup(group.id))
        if (response.status === 204) {
            console.log(`Group ${group.name} deleted successfully`)
        }
    }

    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>Groups</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Groups"
                    links={[
                        {
                            name: "Security",
                        },
                        {
                            name: "Groups",
                            href: PATHS.auth.groups.list(),
                        },
                        // {
                        //     name: "List",
                        // },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(PATHS.auth.groups.create())}
                            disabled={!hasPermission(Permissions.GROUP_CREATE)}
                        >
                            Create a Group
                        </Button>
                    }
                />
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>ID</TableCell> */}
                                    <TableCell>Name</TableCell>
                                    <TableCell>Permissions #</TableCell>
                                    <TableCell>Users #</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groups.map((group) => (
                                    <TableRow key={group.id} hover>
                                        {/* <TableCell>{material.id}</TableCell> */}
                                        <TableCell>{group.name}</TableCell>
                                        <TableCell>{group.permissions.length}</TableCell>
                                        <TableCell>{group.users.length}</TableCell>
                                        <TableCell>
                                            <ButtonGroup variant="outlined" size="small">
                                                <Button
                                                    onClick={() =>
                                                        navigate(
                                                            PATHS.auth.groups.details(group.id)
                                                        )
                                                    }
                                                    disabled={
                                                        !hasPermission(Permissions.GROUP_UPDATE)
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    color="error"
                                                    onClick={() => onDelete(group)}
                                                    disabled={
                                                        !hasPermission(Permissions.GROUP_DELETE)
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
