import { LineItemTypeUpdate, LineItemTypeCreate } from "src/@types/lineItemType"
import axios from "../utils/axios"

const apiFetchLineItemTypes = () => axios.get("/api/v1/quotes/line-item-types/")

const apiFetchLineItemType = (id: number) => axios.get(`/api/v1/quotes/line-item-types/${id}/`)

const apiUpdateLineItemType = (id: number, changes: LineItemTypeUpdate) =>
    axios.patch(`/api/v1/quotes/line-item-types/${id}/`, changes)

const apiCreateLineItemType = (payload: LineItemTypeCreate) =>
    axios.post(`/api/v1/quotes/line-item-types/`, payload)

const apiDeleteLineItemType = async (id: number) => {
    try {
        return await axios.delete(`/api/v1/quotes/line-item-types/${id}/`)
    } catch (error) {
        console.log("Error:", error)
        return error.response
    }
}

export {
    apiUpdateLineItemType,
    apiFetchLineItemType,
    apiFetchLineItemTypes,
    apiCreateLineItemType,
    apiDeleteLineItemType,
}
