import {
    Autocomplete,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Stack,
    TextField,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router"
import { LineItemTypeUpdate } from "src/@types/lineItemType"
import { MaterialTypeType } from "src/@types/material-type"
import { MeasurementTypeType } from "src/@types/measurementType"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import CategorySelectTree from "src/forms/category-select"
import LineItemTypeEditForm from "src/forms/line-item-type-edit"
import { getCategories } from "src/redux/slices/categories"
import { getLineItemType, updateLineItemType } from "src/redux/slices/lineItemTypes"
import { getMaterialTypes } from "src/redux/slices/material-types"
import { getMeasurementTypes } from "src/redux/slices/measurementTypes"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"

export default function LineItemTypeDetailsPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        dispatch(getMeasurementTypes())
        dispatch(getMaterialTypes())
        dispatch(getCategories())
    }, [dispatch])

    useEffect(() => {
        const getLineItemTypeAsync = async () => {
            if (idAsNumber) {
                await dispatch(getLineItemType(idAsNumber))

                setIsLoading(false)
            }
        }
        getLineItemTypeAsync()
    }, [dispatch, idAsNumber])

    const lineItemType = useSelector((state) =>
        state.lineItemTypes.lineItemTypes.find((item) => item.id === idAsNumber)
    )
    const measurementTypes = useSelector((state) => state.measurementTypes.measurementTypes)
    const materials = useSelector((state) => state.materialTypes.materialTypes)
    // const categories = useSelector((state) => state.categories.categories.byId)

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (lineItemType === undefined) {
        return <div>Not found</div>
    }

    console.log(lineItemType)

    const materialPossibleIds = lineItemType.materials_possible.map((item) => item.id)

    const onSubmit = async (data: LineItemTypeUpdate) => {
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Service Type updated successfully", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDefaultMeasurementsChange = async (event: any, value: MeasurementTypeType[]) => {
        const changes = {
            measurements_default_ids: value.map((item) => item.id),
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Default measurements updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlePossibleMeasurementsChange = async (event: any, value: MeasurementTypeType[]) => {
        const changes = {
            measurements_possible_ids: value.map((item) => item.id),
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Possible measurements updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlePossibleMaterialsChange = async (event: any, value: MaterialTypeType[]) => {
        const changes = {
            materials_possible_ids: value.map((item) => item.id),
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Possible materials updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDefaultMaterialOp1Change = async (event: any, value: MaterialTypeType | null) => {
        const changes = {
            material_default_op_1_id: value?.id || null,
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Default material type updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDefaultMaterialOp2Change = async (event: any, value: MaterialTypeType | null) => {
        const changes = {
            material_default_op_2_id: value?.id || null,
        }
        try {
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Default material type updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlePossibleMaterialsCategoryChange = async (value: number | null) => {
        const changes = {
            materials_possible_category_ids: value ? [value] : [],
        }
        try {
            console.log(changes)
            const response = await dispatch(updateLineItemType(idAsNumber, changes))
            console.log(response)
            if (response.status === 200) {
                enqueueSnackbar("Possible materials category updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    console.log(lineItemType)

    return (
        <>
            <Helmet>
                <title>Update Service Type</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Update a Service Type"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Service Types",
                            href: PATHS.quotes.lineItemTypes.list(),
                        },
                        {
                            name: "Update",
                        },
                    ]}
                />
                <LineItemTypeEditForm
                    onSubmit={onSubmit}
                    initialData={lineItemType}
                    measurementTypes={lineItemType.measurements_possible}
                />
                <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Measurements
                </Typography>
                <Card>
                    <CardContent>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={2} width="100%">
                            <Stack width={{ xs: "100%", md: "50%" }}>
                                <Autocomplete
                                    id="possible-measurements"
                                    getOptionLabel={(option) => option.name}
                                    multiple
                                    onChange={handlePossibleMeasurementsChange}
                                    value={measurementTypes.filter(
                                        (item) =>
                                            lineItemType?.measurements_possible
                                                .map((mt) => mt.id)
                                                .includes(item.id)
                                    )}
                                    options={measurementTypes.filter(
                                        (item) =>
                                            !lineItemType?.measurements_possible
                                                .map((mt) => mt.id)
                                                .includes(item.id)
                                    )}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Possible Measurements" />
                                    )}
                                />
                            </Stack>
                            <Stack width={{ xs: "100%", md: "50%" }}>
                                <Autocomplete
                                    id="default-measurements"
                                    getOptionLabel={(option) => option.name}
                                    multiple
                                    onChange={handleDefaultMeasurementsChange}
                                    value={measurementTypes.filter(
                                        (item) =>
                                            lineItemType?.measurements_default
                                                .map((mt) => mt.id)
                                                .includes(item.id)
                                    )}
                                    options={measurementTypes.filter(
                                        (item) =>
                                            !lineItemType?.measurements_default
                                                .map((mt) => mt.id)
                                                .includes(item.id)
                                    )}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Default Measurements" />
                                    )}
                                />
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Materials
                </Typography>
                <Card>
                    <CardContent>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={2} width="100%">
                            <Stack width={{ xs: "100%", md: "50%" }} spacing={2}>
                                <Autocomplete
                                    id="possible-materials"
                                    getOptionLabel={(option) => option.name}
                                    multiple
                                    onChange={handlePossibleMaterialsChange}
                                    value={materials.filter((item) =>
                                        materialPossibleIds.includes(item.id)
                                    )}
                                    options={materials.filter(
                                        (item) => !materialPossibleIds.includes(item.id)
                                    )}
                                    // sx={{ width: "100%", marginBottom: 2 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Possible Materials" />
                                    )}
                                />
                                <Autocomplete
                                    id="default-material-op-1"
                                    getOptionLabel={(option) => option.name}
                                    onChange={handleDefaultMaterialOp1Change}
                                    value={
                                        materials.find(
                                            (item) =>
                                                lineItemType?.material_default_op_1?.id === item.id
                                        ) || null
                                    }
                                    options={materials.filter(
                                        (item) =>
                                            lineItemType?.material_default_op_1?.id !== item.id
                                    )}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Default Material Option 1" />
                                    )}
                                />
                                <Autocomplete
                                    id="default-material-op-2"
                                    getOptionLabel={(option) => option.name}
                                    onChange={handleDefaultMaterialOp2Change}
                                    value={
                                        materials.find(
                                            (item) =>
                                                lineItemType?.material_default_op_2?.id === item.id
                                        ) || null
                                    }
                                    options={materials.filter(
                                        (item) =>
                                            lineItemType?.material_default_op_2?.id !== item.id
                                    )}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Default Material Option 2" />
                                    )}
                                />
                            </Stack>
                            <Stack width={{ xs: "100%", md: "50%" }}>
                                <Typography sx={{ mb: 2 }}>
                                    <b>Use possible materials from this category:</b>
                                </Typography>
                                <CategorySelectTree
                                    current={
                                        lineItemType.materials_possible_categories.length > 0
                                            ? `${lineItemType.materials_possible_categories[0]?.id}`
                                            : "none"
                                    }
                                    onChange={(value) =>
                                        handlePossibleMaterialsCategoryChange(value)
                                    }
                                    disable={[]}
                                    emptyLabel="No Category"
                                />
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Container>
        </>
    )
}
