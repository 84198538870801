import { Divider, IconButton, Stack, Typography } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { MeasurementType } from "src/@types/measurement"
import { useDispatch } from "src/redux/store"
import { deleteMeasurement } from "src/redux/slices/quotes"
import { useSnackbar } from "notistack"
import MeasurementField from "./measurement-field"

type Props = {
    measurement: MeasurementType
    pos: number
    // onUpdate: (measurement: MeasurementType, fieldOrder: number, value: number | null) => void
    lineItemMeasurements: MeasurementType[]
}

export default function Measurement({ measurement, pos, lineItemMeasurements }: Props) {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const onDelete = async () => {
        if (!window.confirm("Are you sure you want to delete this measurement?")) return
        const response = await dispatch(deleteMeasurement(measurement))
        if (response.status === 200) {
            enqueueSnackbar("Measurement deleted", { variant: "success" })
        }
    }

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">
                    Measurement {pos + 1}: {measurement.name}
                </Typography>
                {lineItemMeasurements.length > 1 && (
                    <IconButton size="small" color="error" onClick={onDelete}>
                        <DeleteIcon />
                    </IconButton>
                )}
            </Stack>
            {measurement.m_fields.map((field, i) => (
                <MeasurementField
                    key={`measurement.${measurement.id}.field.${i}`}
                    field={field}
                    // onUpdate={(value: number | null) => onUpdate(measurement, i, value)}
                />
            ))}
            {(lineItemMeasurements.length > 1 || true) && (
                <Divider
                    key={`measurements.${measurement.id}.divider`}
                    color="disabled"
                    sx={{ width: "100%" }}
                />
            )}
        </>
    )
}
