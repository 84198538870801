import {
    Button,
    ButtonGroup,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    Link,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router"
import CircularProgress from "src/components/circular-progress"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getQuote, updatePackage, updateQuote } from "src/redux/slices/quotes"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import "./base.css"
import { MaterialType } from "src/@types/material"
import { calculatePackageTotal } from "src/services/package-service"
import { PackageItemType } from "src/@types/package"
import { TFinancingOption } from "src/@types/financing-option"
import { SFEstimate } from "src/@types/sf-estimate"
import { Permissions } from "src/@types/security"
import { FeeType } from "src/@types/fee"
import ReactToPrint from "react-to-print"
import { useAuthContext } from "src/auth/useAuthContext"
import Logo from "./logo.png"
import LogoLightToANewDegree from "./light-to-a-degree-logo.jpeg"
import LogoTransparent from "./logo-transparent.jpeg"
import BottomImage from "./bottom-image.png"
// import LineItemOneOptionRow from "./line-item-one-option-row"
import LineItemRow from "./line-item-row"
import FeeItemRow from "./fee-item-row"
import EditNumberCell from "./edit-number-cell"
import LegalTerms from "./toc.json"
import JHATerms from "./jha.json"
import EditTextCell from "./edit-text-cell"

type priceDisplayMode = "plain" | "financing" | "both"

export function calculateMonthlyPayment(
    principal: number,
    apr: number,
    termInMonths: number
): number {
    console.log({ principal, apr, termInMonths })
    if (apr === 0) return principal / termInMonths

    const monthlyInterestRate = apr / 12 / 100
    const numerator = monthlyInterestRate * (1 + monthlyInterestRate) ** termInMonths
    const denominator = (1 + monthlyInterestRate) ** termInMonths - 1
    const monthlyPayment = principal * (numerator / denominator)
    return monthlyPayment
}

export const formatPackagePrice = (
    price: number,
    financingOption: TFinancingOption | null,
    mode: priceDisplayMode
): JSX.Element => {
    const plainView = price.toLocaleString("en-US")
    if (financingOption === null || price < financingOption.min_amount || mode === "plain")
        return <>{plainView}</>

    const monthlyPayment = calculateMonthlyPayment(price, financingOption.apr, financingOption.term)
    const total = Math.round(monthlyPayment * financingOption.term)

    if (!monthlyPayment || !total) return <>{plainView}</>

    console.log({ monthlyPayment, total })

    const financingView = (
        <>
            {Math.round(monthlyPayment).toLocaleString("en-US")}/mo.
            <span style={{ fontSize: 10 }}>*</span>
        </>
    )

    if (mode === "financing") return financingView

    return (
        <>
            {financingView} or {plainView}
        </>
    )
}

const getEstimateAddress = (estimate: SFEstimate | null | undefined) => {
    if (!estimate || !estimate.street_1) return ""
    return `${estimate.street_1}, ${estimate.city}, ${estimate.state_prov} ${estimate.postal_code}`
}

export default function QuoteViewPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const navigate = useNavigate()

    const { hasPermission } = useAuthContext()

    const [isLoading, setIsLoading] = useState(true)
    const [priceDisplayMode, setPriceDisplayMode] = useState<priceDisplayMode>("both")

    const dispatch = useDispatch()

    const componentRef = useRef<any>(null)

    useEffect(() => {
        const getQuoteAndLineItemsAsync = async () => {
            if (idAsNumber) {
                await dispatch(getQuote(idAsNumber))
                setIsLoading(false)
            }
        }
        getQuoteAndLineItemsAsync()
    }, [dispatch, idAsNumber])

    const quote = useSelector((state) => state.quotes.quotes.find((item) => item.id === idAsNumber))
    let lineItems = useSelector((state) => state.quotes.lineItemsByQuoteId[idAsNumber]) || []
    lineItems = lineItems.filter((lineItem) => lineItem.value)
    const fees = useSelector((state) => state.quotes.feesByQuoteId[idAsNumber]) || []
    const packages = useSelector((state) => state.quotes.packagesByQuoteId[idAsNumber]) || []
    const packagesMap = {
        null: packages[0],
        [packages[1]?.id]: packages[1],
        [packages[2]?.id]: packages[2],
        [packages[3]?.id]: packages[3],
    }
    const packageItemsByPackageId = useSelector((state) => state.quotes.packageItemsByPackageId)
    const materialsByLineItemId = useSelector((state) => state.quotes.materialsByLineItemId)
    const materialsById: {
        [key: number]: MaterialType
    } = {}
    lineItems.forEach((lineItem) => {
        materialsByLineItemId[lineItem.id].forEach((material) => {
            materialsById[material.id] = material
        })
    })
    const feesById: {
        [key: number]: FeeType
    } = {}
    fees.forEach((fee) => {
        feesById[fee.id] = fee
    })

    const [signature, setSignature] = useState(quote?.signature || "")
    const signatureRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (quote) {
            setSignature(quote.signature || "")
        }
    }, [quote])

    const onSignatureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setSignature(value)
        if (signatureRef.current) clearTimeout(signatureRef.current)
        signatureRef.current = setTimeout(() => {
            dispatch(updateQuote(idAsNumber, { signature: value }))
        }, 2000)
    }

    console.log({ packageItemsByPackageId, materialsById })

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (quote === undefined) {
        return <div>Not found</div>
    }

    console.log({ quote, lineItems })

    const onChangeChosenPackage = (packageId: number | null) => {
        dispatch(updateQuote(idAsNumber, { chosen_package_id: packageId }))
    }

    return (
        <>
            <Helmet>
                <title>{`Quote #${quote?.sf_estimate?.number || ""}`}</title>
            </Helmet>
            <Container className="printable-fullwidth">
                <CustomBreadcrumbs
                    heading="View Quote"
                    links={[
                        // {
                        //     name: "Quotes",
                        // },
                        {
                            name: "Quotes",
                            href: PATHS.quotes.quotes.list(),
                        },
                        {
                            name: "View",
                        },
                    ]}
                    action={
                        <ButtonGroup variant="contained" size="small">
                            <Button
                                onClick={() => {
                                    navigate(PATHS.quotes.quotes.details(idAsNumber))
                                }}
                            >
                                Edit
                            </Button>
                            {hasPermission(Permissions.QUOTE_EXPORT) && (
                                <Button
                                    onClick={() => {
                                        navigate(PATHS.quotes.quotes.export(idAsNumber))
                                    }}
                                >
                                    Export
                                </Button>
                            )}
                            <ReactToPrint
                                trigger={() => <Button variant="outlined">Print</Button>}
                                content={() => componentRef.current}
                            />
                        </ButtonGroup>
                    }
                />
                <div ref={componentRef}>
                    <Grid container spacing={0} className="printable">
                        <Grid item xs={12} spacing={0}>
                            {/* </Grid>
                    <Grid item container xs={4}> */}
                            <table className="quote-table">
                                {/* <thead> */}
                                <tr>
                                    <td
                                        style={{
                                            borderRight: "2px solid transparent",
                                            borderLeft: "2px solid transparent",
                                            borderTop: "2px solid transparent",
                                            paddingLeft: 0,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        }}
                                        colSpan={4}
                                    >
                                        <Typography sx={{ color: "#208fce", pb: 0.5 }} variant="h6">
                                            Holiday Lights Quote
                                        </Typography>
                                    </td>
                                    <td
                                        rowSpan={3}
                                        colSpan={4}
                                        style={{
                                            borderRight: "2px solid transparent",
                                            borderTop: "2px solid transparent",
                                            paddingRight: 0,
                                            paddingTop: 0,
                                            verticalAlign: "top",
                                        }}
                                    >
                                        <img
                                            src={Logo}
                                            alt="logo"
                                            style={{ width: "80px", float: "right" }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={3}> Date</th>
                                    <th>Number</th>
                                </tr>
                                <tr className="text-regular">
                                    <td style={{ textAlign: "center" }} colSpan={3}>
                                        {quote.sf_estimate?.start_date
                                            ? new Date(
                                                  quote.sf_estimate?.start_date
                                              ).toLocaleDateString()
                                            : null}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {quote.sf_estimate?.number}
                                    </td>
                                </tr>
                                {/* </tbody> */}
                                {/* <thead> */}
                                <tr>
                                    <th style={{}} colSpan={4}>
                                        Client Information
                                    </th>
                                    <th colSpan={4}>Lease Details</th>
                                </tr>
                                {/* </thead> */}
                                {/* <tbody> */}
                                <tr>
                                    <td
                                        colSpan={4}
                                        style={{ borderBottom: "none", textAlign: "center" }}
                                        className="text-regular"
                                    >
                                        {quote.sf_estimate?.customer.name}
                                    </td>
                                    <EditTextCell
                                        state={quote.notes}
                                        onChange={(value: string) => {
                                            dispatch(updateQuote(idAsNumber, { notes: value }))
                                        }}
                                        name="Job Notes / Instructions"
                                        colSpan={4}
                                        // cellStyle={{ borderBottom: "none" }}
                                    />
                                    {/* <td
                                    style={{ borderBottom: "none" }}
                                    colSpan={4}
                                    className="text-small"
                                >
                                    Price includes all materials and labor required for install, maintenance, removal, & storage of all products. Customer’s responsible for providing & maintaining adequate power. WiseGuys Pro-Wash owns all materials used for installation including the lights.
                                </td> */}
                                </tr>
                                {/* </tbody> */}
                                {/* </table> */}
                                {/* </Grid> */}
                                {/* <Grid item xs={4} /> */}
                                {/* <Grid item container xs={4} spacing={0} justifyContent="flex-end">
                        <Grid item xs={12}> */}

                                {/* </Grid>
                        <Grid item xs={12} spacing={0}> */}
                                {/* <table className="quote-table" style={{ borderBottom: "none" }}>
                                <thead>
                                    <tr>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        
                                    </tr>
                                </tbody>
                            </table> */}
                                {/* </Grid> */}
                                {/* </Grid>
                    <Grid item xs={12} spacing={0}> */}
                                {/* <table className="quote-table"> */}
                                {/* <thead> */}
                                <tr>
                                    <th
                                    // rowSpan={2}
                                    >
                                        1
                                    </th>
                                    <th
                                    // rowSpan={2}
                                    >
                                        2
                                    </th>
                                    <th
                                    // rowSpan={2}
                                    >
                                        3
                                    </th>
                                    <th
                                        // rowSpan={2}
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        Light Service
                                    </th>
                                    <th
                                    // rowSpan={2}
                                    >
                                        Description
                                    </th>
                                    <th style={{ width: 56 }}>White</th>
                                    <th style={{ width: 56 }}>Dynamic</th>
                                    {/* <th colSpan={2}>Amount</th> */}
                                    <th
                                    // rowSpan={2}
                                    />
                                    {/* <input type="checkbox" checked style={{border: "1px solid white"}} /></th> */}
                                </tr>
                                {/* <tr>
                                
                            </tr> */}
                                {/* </thead> */}
                                {/* <tbody> */}
                                {lineItems.map((lineItem) => {
                                    const packageItems: PackageItemType[] = []
                                    packages.forEach((p) => {
                                        const packageItem = packageItemsByPackageId[p.id].find(
                                            (pi) => pi.line_item_id === lineItem.id
                                        )
                                        if (packageItem) {
                                            packageItems.push(packageItem)
                                        }
                                    })
                                    return (
                                        <LineItemRow
                                            lineItem={lineItem}
                                            key={lineItem.id}
                                            packageItems={packageItems}
                                            packages={packages}
                                            materials={materialsByLineItemId[lineItem.id]}
                                        />
                                    )
                                })}
                                {fees.map((fee) => {
                                    const packageItems: PackageItemType[] = []
                                    packages.forEach((p) => {
                                        const packageItem = packageItemsByPackageId[p.id].find(
                                            (pi) => pi.fee_id === fee.id
                                        )
                                        if (packageItem) {
                                            packageItems.push(packageItem)
                                        }
                                    })
                                    return (
                                        <FeeItemRow
                                            fee={fee}
                                            key={fee.id}
                                            packageItems={packageItems}
                                            packages={packages}
                                        />
                                    )
                                })}
                                {/* <tr>
                                    <td style={{ textAlign: "center", backgroundColor: "orange" }}>
                                        <input type="checkbox" checked disabled />
                                    </td>
                                    <td style={{ textAlign: "center", backgroundColor: "yellow" }}>
                                        <input type="checkbox" checked disabled />
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            backgroundColor: "LawnGreen",
                                        }}
                                    >
                                        <input type="checkbox" checked disabled />
                                    </td>
                                    <td className="text-regular">Supplies & Materials</td>
                                    <td />
                                    <td
                                        style={{ textAlign: "center" }}
                                        colSpan={2}
                                        className="text-regular"
                                    >
                                        {suppliesAndMaterialsRounded}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <input type="checkbox" checked disabled />
                                    </td>
                                </tr> */}
                                {/* </tbody> */}
                            </table>
                        </Grid>
                    </Grid>
                    <Stack spacing={1} direction="column">
                        {quote.financing_options.length > 0 && (
                            <Stack
                                direction="row"
                                spacing={1}
                                className="non-printable"
                                alignItems="center"
                                sx={{ mt: 2 }}
                            >
                                <Typography variant="body2">
                                    <b>Financing:</b>
                                </Typography>

                                <ToggleButtonGroup
                                    size="small"
                                    value={priceDisplayMode}
                                    exclusive
                                    onChange={(e, value) => {
                                        if (value) setPriceDisplayMode(value)
                                    }}
                                    sx={{ padding: 0 }}
                                >
                                    <ToggleButton
                                        value="plain"
                                        sx={{ padding: 1, lineHeight: 0.7 }}
                                    >
                                        Total
                                    </ToggleButton>
                                    <ToggleButton
                                        value="financing"
                                        sx={{ padding: 1, lineHeight: 0.7 }}
                                    >
                                        Financing
                                    </ToggleButton>
                                    <ToggleButton value="both" sx={{ padding: 1, lineHeight: 0.7 }}>
                                        Both
                                    </ToggleButton>
                                </ToggleButtonGroup>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(quote.chosen_financing_option_id)}
                                            onChange={(e) => {
                                                dispatch(
                                                    updateQuote(idAsNumber, {
                                                        chosen_financing_option_id: e.target.checked
                                                            ? quote.financing_options[0].id
                                                            : null,
                                                    })
                                                )
                                            }}
                                        />
                                    }
                                    label="Client using WiseTack"
                                    sx={{ fontSize: 12 }}
                                />
                            </Stack>
                        )}
                        <table className="quote-table packages-table" style={{ marginTop: 0 }}>
                            <thead>
                                <tr>
                                    <th colSpan={3}>Service Packages</th>
                                    <th colSpan={2} style={{ width: "152px" }} />
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            width: "calc((100% - 152px) / 3)",
                                            // maxWidth: "30%",
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            backgroundColor: "orange",
                                            textAlign: "center",
                                            fontWeight: 700,
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            style={{ marginRight: 8 }}
                                            checked={quote.chosen_package_id === packages[1].id}
                                            onChange={(e) =>
                                                onChangeChosenPackage(
                                                    e.target.checked ? packages[1].id : null
                                                )
                                            }
                                        />
                                        1. {packages[1].name}
                                    </td>
                                    <td
                                        style={{
                                            width: "calc((100% - 152px) / 3)",
                                            // minWidth: "30%",
                                            // maxWidth: "30%",
                                            backgroundColor: "yellow",
                                            textAlign: "center",
                                            fontWeight: 700,
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            style={{ marginRight: 8 }}
                                            checked={quote.chosen_package_id === packages[2].id}
                                            onChange={(e) =>
                                                onChangeChosenPackage(
                                                    e.target.checked ? packages[2].id : null
                                                )
                                            }
                                        />
                                        2. {packages[2].name}
                                    </td>
                                    <td
                                        style={{
                                            width: "calc((100% - 152px) / 3)",
                                            // minWidth: "25%",
                                            // maxWidth: "30%",
                                            backgroundColor: "LawnGreen",
                                            textAlign: "center",
                                            fontWeight: 700,
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            style={{ marginRight: 8 }}
                                            checked={quote.chosen_package_id === packages[3].id}
                                            onChange={(e) =>
                                                onChangeChosenPackage(
                                                    e.target.checked ? packages[3].id : null
                                                )
                                            }
                                        />
                                        3. {packages[3].name}
                                    </td>
                                    <td
                                        // width="120px"
                                        style={{
                                            width: "100px",
                                            backgroundColor: "white",
                                            textAlign: "center",
                                        }}
                                    >
                                        Selected
                                    </td>
                                    <td
                                        style={{
                                            // width: "50px",
                                            backgroundColor: "white",
                                            textAlign: "center",
                                            color: "black",
                                        }}
                                    >
                                        {calculatePackageTotal(
                                            packageItemsByPackageId[
                                                packagesMap[quote.chosen_package_id || "null"].id
                                            ],
                                            // quote.chosen_package_id
                                            //     ? quote.chosen_package_id]
                                            //     : packageItemsByPackageId[packages[0].id],
                                            materialsById,
                                            feesById
                                            // suppliesAndMaterialsRounded
                                        ).toLocaleString("en-US")}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            // width: "25%",
                                            backgroundColor: "orange",
                                            textAlign: "center",
                                        }}
                                    >
                                        {formatPackagePrice(
                                            calculatePackageTotal(
                                                packageItemsByPackageId[packages[1].id],
                                                materialsById,
                                                feesById
                                                // suppliesAndMaterialsRounded
                                            ) - packages[1].discount_value,
                                            quote.financing_options[0] || null,
                                            priceDisplayMode
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            // width: "25%",
                                            backgroundColor: "yellow",
                                            textAlign: "center",
                                        }}
                                    >
                                        {formatPackagePrice(
                                            calculatePackageTotal(
                                                packageItemsByPackageId[packages[2].id],
                                                materialsById,
                                                feesById
                                                // suppliesAndMaterialsRounded
                                            ) - packages[2].discount_value,
                                            quote.financing_options[0] || null,
                                            priceDisplayMode
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            // width: "25%",
                                            backgroundColor: "LawnGreen",
                                            textAlign: "center",
                                        }}
                                    >
                                        {formatPackagePrice(
                                            calculatePackageTotal(
                                                packageItemsByPackageId[packages[3].id],
                                                materialsById,
                                                feesById
                                                // suppliesAndMaterialsRounded
                                            ) - packages[3].discount_value,
                                            quote.financing_options[0] || null,
                                            priceDisplayMode
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            // width: "12.5%",
                                            backgroundColor: "white",
                                            textAlign: "center",
                                        }}
                                    >
                                        Discount
                                    </td>
                                    <EditNumberCell
                                        state={
                                            packagesMap[quote.chosen_package_id || "null"]
                                                .discount_value
                                        }
                                        // cellStyle={{ fontSize: 14 }}
                                        onChange={(value) => {
                                            dispatch(
                                                updatePackage(
                                                    packagesMap[quote.chosen_package_id || "null"]
                                                        .id,
                                                    {
                                                        discount_value: value,
                                                    }
                                                )
                                            )
                                        }}
                                        name={packages[0].name}
                                        cellStyle={{ color: "black" }}
                                    />
                                    {/* <td style={{ width: "12.5%", backgroundColor: "white" }}>
                                    {packages[0].discount_value}
                                </td> */}
                                </tr>
                                <tr>
                                    <EditNumberCell
                                        state={packages[1].discount_value}
                                        cellStyle={{
                                            backgroundColor: "orange",
                                            // fontSize: 16
                                        }}
                                        onChange={(value) => {
                                            dispatch(
                                                updatePackage(packages[1].id, {
                                                    discount_value: value,
                                                })
                                            )
                                        }}
                                        name={packages[1].name}
                                        formatState={(value) => `Save $${value?.toLocaleString()}`}
                                    />
                                    <EditNumberCell
                                        state={packages[2].discount_value}
                                        cellStyle={{
                                            backgroundColor: "yellow",
                                            // fontSize: 16
                                        }}
                                        onChange={(value) => {
                                            dispatch(
                                                updatePackage(packages[2].id, {
                                                    discount_value: value,
                                                })
                                            )
                                        }}
                                        name={packages[2].name}
                                        formatState={(value) => `Save $${value?.toLocaleString()}`}
                                    />
                                    <EditNumberCell
                                        state={packages[3].discount_value}
                                        cellStyle={{ backgroundColor: "LawnGreen", fontSize: 16 }}
                                        onChange={(value) => {
                                            dispatch(
                                                updatePackage(packages[3].id, {
                                                    discount_value: value,
                                                })
                                            )
                                        }}
                                        name={packages[3].name}
                                        formatState={(value) => `Save $${value?.toLocaleString()}`}
                                    />
                                    <td
                                        style={{
                                            // width: "12.5%",
                                            backgroundColor: "white",
                                            textAlign: "center",
                                        }}
                                    >
                                        {/* <input type="checkbox" style={{ marginRight: 8 }} /> */}
                                        Total
                                    </td>
                                    <td
                                        style={{
                                            // width: "12.5%",
                                            backgroundColor: "white",
                                            textAlign: "center",
                                            color: "black",
                                        }}
                                    >
                                        {(
                                            calculatePackageTotal(
                                                packageItemsByPackageId[
                                                    packagesMap[quote.chosen_package_id || "null"]
                                                        .id
                                                ],
                                                materialsById,
                                                feesById
                                                // suppliesAndMaterialsRounded
                                            ) -
                                            packagesMap[quote.chosen_package_id || "null"]
                                                .discount_value
                                        ).toLocaleString("en-US")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Stack spacing={1} direction="column" sx={{ mt: 1 }}>
                            <div className="text-small">
                                * Three month term subject to credit approval and varies. Finance
                                options with APR rates from 0-35.9% based on creditworthiness
                                provided by Wisetack’s lending partners. Details at{" "}
                                <Link
                                    href="https://wisetack.com/faqs"
                                    color="#208fce"
                                    target="_blank"
                                >
                                    wisetack.com/faqs
                                </Link>
                            </div>
                            {/* <div className="text-small">
                                The full amount for materials, installation, and removal must be
                                paid in full upon completion of the installation. WiseGuys Pro-Wash
                                is not responsible for any products damaged or lost due to
                                vandalism, extreme weather conditions, or acts of god and will make
                                efforts to replace any damaged product for an additional charge. No
                                warranty or complimentary repair service is expressed or implied,
                                unless noted in writing in this agreement.
                            </div> */}
                            <div className="text-small">
                                WiseGuys Pro-Wash replaces reported malfunctioning products, but
                                cannot guarantee that every bulb remains lit during the length of
                                the lease. Customers are responsible for replacing items damaged or
                                lost due to vandalism or acts of nature, incurring an additional
                                charge. “Supplies” added to all jobs. A 50% credit card or ACH
                                deposit is due upon job scheduling with balance charged upon
                                completing installation for the job’s total (including materials and
                                removal) unless financed through WiseTack.
                            </div>
                            {/* <div className="text-small"> */}
                            {/* &quot;Supplies & Materials&quot; added to all jobs (dependent on size).
                            A 50% credit card deposit due upon job scheduling with balance charged
                            upon completing installation. */}
                            {/* &quot;Supplies&quot; added to all jobs (dependent on size). A 50%
                                credit card or ACH deposit due upon job scheduling with balance
                                charged upon completing installation for job total (including
                                materials & removal) unless financed through WiseTack.
                            </div> */}
                        </Stack>
                        <Grid container spacing={0} className="printable">
                            <Grid
                                item
                                // xs={7}
                                width="50%"
                                spacing={0}
                                container
                                alignItems="flex-start"
                                direction="column"
                                alignContent="flex-start"
                                justifyContent="flex-start"
                            >
                                <img
                                    src={BottomImage}
                                    alt="bottom-logo"
                                    style={{ width: "196px", marginTop: 4 }}
                                />
                                <img
                                    src={LogoLightToANewDegree}
                                    alt="bottom-logo"
                                    style={{ width: "196px", paddingBottom: 8, marginTop: 0 }}
                                />
                                {/* <Typography
                                    sx={{ color: "#208fce", fontSize: 20, fontWeight: "bold" }}
                                >
                                    Light to a new degree!
                                </Typography> */}
                            </Grid>
                            <Grid
                                item
                                // xs={5}
                                width="50%"
                                spacing={0}
                            >
                                <table style={{ width: "100%", marginTop: 6, marginLeft: -4 }}>
                                    <tr>
                                        <td
                                            style={{
                                                width: "40%",
                                                color: "#208fce",
                                                fontSize: 12,
                                                fontWeight: 700,
                                                textAlign: "left",
                                                paddingRight: 8,
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Customer Signature:
                                        </td>
                                        <td style={{ width: "60%" }}>
                                            <input
                                                type="text"
                                                style={{
                                                    width: "100%",
                                                    textAlign: "center",
                                                    color: "black",
                                                    border: "none",
                                                    borderBottom: "1px solid #208fce",
                                                    fontFamily: "Euphoria Script",
                                                    fontSize: 20,
                                                    outline: "none",
                                                    WebkitBorderRadius: 0,
                                                    borderRadius: 0,
                                                    WebkitAppearance: "none",
                                                }}
                                                value={signature}
                                                onChange={onSignatureChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={2}
                                            style={{
                                                // fontSize: 10,
                                                // fontWeight: 700,
                                                paddingTop: 8,
                                                paddingBottom: 8,
                                            }}
                                            className="text-small"
                                        >
                                            {/* <b> */}
                                            {/* Governed by our Terms & Conditions on the reverse. We
                                            expect payment the day after installation by credit card
                                            (+3% service fee) or ACH. */}
                                            Governed by Terms & Conditions on reverse. Payment due
                                            the day after installation by credit card (with 3%
                                            service fee) or ACH, unless financed with WiseTack
                                            (prequalify at{" "}
                                            <Link
                                                href="https://bit.ly/wg-prequal"
                                                target="_blank"
                                                color="#208fce"
                                            >
                                                bit.ly/wg-prequal
                                            </Link>
                                            ).
                                            {/* </b> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                // width: "50%",
                                                color: "#208fce",
                                                fontSize: 12,
                                                fontWeight: 700,
                                                textAlign: "left",
                                                paddingRight: 8,
                                            }}
                                        >
                                            Design Consultant:
                                        </td>
                                        <td
                                            style={{
                                                // width: "50%",
                                                borderBottom: "1px solid #208fce",
                                                textAlign: "center",
                                                color: "black",
                                                fontSize: 12,
                                            }}
                                        >
                                            {`${quote.created_by?.first_name} ${quote.created_by?.last_name}`}
                                        </td>
                                    </tr>
                                </table>
                                {/* <Typography sx={{ display: "inline-block" }}>
                                <p></p>{" "}
                                <div style={{ borderBottom: "1px solid black", width: "50px" }} />
                            </Typography> */}
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack className="toc">
                        <div className="printable-only">
                            <div
                                style={
                                    {
                                        // width: "100%",
                                        // columnCount: 2,
                                        // gap: "2em",
                                        // columnFill: "balance",
                                    }
                                }
                                // className="toc"
                            >
                                <div
                                    style={{
                                        marginBottom: 2,
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: 12,
                                        // columnSpan: "all",
                                    }}
                                >
                                    <b>Terms & Conditions</b>
                                </div>
                                <div>
                                    {/* <ol type="1"> */}
                                    {LegalTerms.map((item, index) => (
                                        // <li key={index}>
                                        <p>
                                            <b>
                                                {index + 1}. {item.title}.
                                            </b>{" "}
                                            {item.text}
                                        </p>
                                        // </li>
                                    ))}
                                    {/* </ol> */}
                                </div>
                            </div>
                        </div>
                    </Stack>
                    <Stack>
                        <div className="printable-only jha">
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        marginBottom: 12,
                                        marginTop: 10,
                                        paddingTop: 20,
                                        lineHeight: 1.2,
                                        backgroundImage: `url(${LogoTransparent})`,
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                    }}
                                >
                                    <b style={{ fontSize: 18 }}>Job Hazard Analysis</b>
                                    <b style={{ fontSize: 14 }}>WiseGuys Pro-Wash</b>
                                    <b style={{ fontSize: 14 }}>
                                        <i>by ExpertSafetyServices.com</i>
                                    </b>
                                </div>
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <td width="10%" style={{ whiteSpace: "nowrap" }}>
                                            <b>Job Name:</b>
                                        </td>
                                        <td width="100%">{quote.sf_estimate?.customer.name}</td>

                                        <td
                                            style={{ textAlign: "right", whiteSpace: "nowrap" }}
                                            width="10%"
                                        >
                                            <b>Filled By:</b>{" "}
                                            {`${quote.created_by?.first_name} ${quote.created_by?.last_name}`}{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                            <b>Job Number:</b>
                                        </td>
                                        <td>{quote.sf_estimate?.number}</td>

                                        <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                                            <b>Employee&apos;s Signature</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                            <b>Job Address:</b>
                                        </td>
                                        <td>{getEstimateAddress(quote?.sf_estimate)}</td>

                                        <td
                                            style={{
                                                borderBottom: "1px solid black",
                                                textAlign: "center",
                                                fontFamily: "Euphoria Script",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {`${quote.created_by?.first_name} ${quote.created_by?.last_name}`}
                                        </td>
                                    </tr>
                                </table>
                                <table
                                    style={{ width: "100%", marginBottom: 6 }}
                                    className="jha-content"
                                >
                                    <thead>
                                        <tr>
                                            <th style={{ width: "180px" }}>Sequence of Work</th>
                                            <th>Potential Hazard</th>
                                            <th>Method for Eliminating or Controlling</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {JHATerms.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.sequence_of_work}</td>
                                                <td>{item.potential_hazard}</td>
                                                <td>
                                                    {item.method}
                                                    {item.additional_information && (
                                                        <>
                                                            <br />
                                                            <i>{item.additional_information}</i>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <b>
                                    The Job Hazard Analysis helps employers identify safety hazards
                                    before they exist in order to reduce the risks both to
                                    themselves and to their customers
                                </b>
                            </div>
                        </div>
                    </Stack>
                </div>
                {/* </Stack> */}
            </Container>
        </>
    )
}
