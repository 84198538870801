import { createSlice, Dispatch } from "@reduxjs/toolkit"
import { LineItemTypeCreate, LineItemTypesState, LineItemTypeUpdate } from "src/@types/lineItemType"
import {
    apiCreateLineItemType,
    apiFetchLineItemType,
    apiFetchLineItemTypes,
    apiUpdateLineItemType,
    apiDeleteLineItemType,
} from "src/api/line-item-types"

const initialState: LineItemTypesState = {
    isLoading: false,
    lineItemTypes: [],
}

const slice = createSlice({
    name: "lineItemTypes",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchLineItemTypesSuccess(state, action) {
            state.isLoading = false
            state.lineItemTypes = action.payload
        },
        fetchLineItemTypeSuccess(state, action) {
            state.isLoading = false
            const index = state.lineItemTypes.findIndex((item) => item.id === action.payload.id)
            if (index !== -1) {
                state.lineItemTypes[index] = action.payload
            } else {
                state.lineItemTypes.push(action.payload)
            }
        },
        deleteLineItemTypeSuccess(state, action) {
            state.isLoading = false
            const index = state.lineItemTypes.findIndex((item) => item.id === action.payload)
            if (index !== -1) {
                state.lineItemTypes.splice(index, 1)
            }
        },
    },
})

export function getLineItemTypes() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchLineItemTypes()
        if (response.status === 200) {
            dispatch(slice.actions.fetchLineItemTypesSuccess(response.data.results))
        }
        return response
    }
}

export function getLineItemType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchLineItemType(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchLineItemTypeSuccess(response.data))
        }
        return response
    }
}

export function createLineItemType(data: LineItemTypeCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateLineItemType(data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchLineItemTypeSuccess(response.data))
        }
        return response
    }
}

export function updateLineItemType(id: number, data: LineItemTypeUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateLineItemType(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchLineItemTypeSuccess(response.data))
        }
        return response
    }
}

export function deleteLineItemType(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteLineItemType(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteLineItemTypeSuccess(id))
        }
        return response
    }
}

export default slice.reducer
