import { createSlice, Dispatch } from "@reduxjs/toolkit"
import { apiCreateCategory, apiFetchCategories, apiFetchCategory, apiUpdateCategory } from "src/api/categories"
import { CategoriesState, CategoryCreate, CategoryUpdate } from "src/@types/category"

const initialState: CategoriesState = {
    isLoading: false,
    categories: {
        byId: {},
        byParentId: {},
    },
}

const slice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true
        },
        fetchCategoriesSuccess(state, action) {
            state.isLoading = false
            action.payload.forEach((category: any) => {
                state.categories.byId[category.id] = category
            })
            console.log(action.payload)
        }
    },
})


export function getCategories() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchCategories()
        if (response.status === 200) {
            dispatch(slice.actions.fetchCategoriesSuccess(response.data.results))
        }
    }
}


export function getCategory(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchCategory(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchCategoriesSuccess([response.data]))
        }
        return response
    }
}


export function createCategory(data: CategoryCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateCategory(data)
        if (response.status === 201) {
            dispatch(slice.actions.fetchCategoriesSuccess([response.data]))
        }
        return response
    }
}


export function updateCategory(id: number, data: CategoryUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateCategory(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchCategoriesSuccess([response.data]))
        }
        return response
    }
}


export default slice.reducer