import { createSlice, Dispatch } from "@reduxjs/toolkit"
import {
    ContainerType,
    ContainerCreate,
    ContainerUpdate,
    ContainersState,
} from "src/@types/containers"
import {
    apiCreateContainer,
    apiDeleteContainer,
    apiFetchContainer,
    apiFetchContainers,
    apiUpdateContainer,
    FetchContainerFilters,
} from "src/api/containers"

const initialState: ContainersState = {
    containers: [],
    isLoading: false,
}

const slice = createSlice({
    name: "containers",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        fetchContainersSuccess: (state, action) => {
            state.isLoading = false
            // REconsile with existing containers
            const newContainers = action.payload
            newContainers.forEach((c: ContainerType) => {
                const index = state.containers.findIndex((item) => item.id === c.id)
                if (index !== -1) {
                    state.containers[index] = c
                } else {
                    state.containers.push(c)
                }
            })
        },
        fetchContainerSuccess: (state, action) => {
            state.isLoading = false
            const container = action.payload
            const index = state.containers.findIndex((item) => item.id === container.id)
            if (index !== -1) {
                state.containers[index] = container
            } else {
                state.containers.push(container)
            }
        },
        createContainerSuccess: (state, action) => {
            state.isLoading = false
            state.containers.push(action.payload)
        },
        deleteContainerSuccess: (state, action) => {
            state.isLoading = false
            state.containers = state.containers.filter((item) => item.id !== action.payload)
        },
    },
})

export function getContainers(filters: FetchContainerFilters = {}) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchContainers(filters)
        if (response.status === 200) {
            dispatch(slice.actions.fetchContainersSuccess(response.data.results))
        }
        return response
    }
}

export function getContainer(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchContainer(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchContainerSuccess(response.data))
        }
        return response
    }
}

export function createContainer(data: ContainerCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateContainer(data)
        if (response.status === 201) {
            dispatch(slice.actions.createContainerSuccess(response.data))
        }
        return response
    }
}

export function updateContainer(id: number, data: ContainerUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateContainer(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchContainerSuccess(response.data))
        }
        return response
    }
}

export function deleteContainer(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteContainer(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteContainerSuccess(id))
        }
        return response
    }
}

export default slice.reducer
