/* eslint-disable */
import {
    Autocomplete,
    Button,
    CircularProgress,
    Container,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getLineItemTypes } from "src/redux/slices/lineItemTypes"
import { getCategories } from "src/redux/slices/categories"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { Category } from "src/@types/category"
import { LineItemTypeType } from "src/@types/lineItemType"
import {
    createQuoteTemplateItem,
    getQuoteTemplate,
    reorderQuoteTemplateItems,
    updateQuoteTemplate,
} from "src/redux/slices/quote-templates"
import QuoteTemplateEditForm from "src/forms/quote-template-edit"
import { QuoteTemplateItemType, QuoteTemplateUpdate } from "src/@types/quote-template"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import QuoteTemplateItem from "./item"
import { getFeeTypes } from "src/redux/slices/fee-types"
import { FeeTypeType } from "src/@types/fee-type"
import { TFinancingOptionType } from "src/@types/financing-option-type"
import { getFinancingOptionTypes } from "src/redux/slices/financing-option-types"

const getAllCategoryChildren = (id: number | null, categories: Category[]): Category[] => {
    if (id === null) return []
    const children = categories.filter((item) => item.parent_id === id)
    if (children.length === 0) return []

    return children.reduce((acc: Category[], category: Category) => {
        if (acc.includes(category)) return acc
        return acc.concat(category, getAllCategoryChildren(category.id, categories))
    }, [])
}

export default function QuoteTemplateDetailsPage() {
    const { id } = useParams<{ id: string }>()

    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const [isLoading, setIsLoading] = useState(true)
    const [lineItemTypeToAdd, setLineItemTypeToAdd] = useState<LineItemTypeType | null>(null)
    const [feeTypeToAdd, setFeeTypeToAdd] = useState<FeeTypeType | null>(null)
    const [financingOptionToAdd, setFinancingOptionToAdd] = useState<TFinancingOptionType | null>(
        null
    )
    const [isSending, setIsSending] = useState(false)
    const [itemsState, setItemsState] = useState<QuoteTemplateItemType[]>([])

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        dispatch(getCategories())
        dispatch(getLineItemTypes())
        dispatch(getFeeTypes())
        dispatch(getFinancingOptionTypes())
    }, [dispatch])

    useEffect(() => {
        const getQuoteTemplateAsync = async () => {
            if (idAsNumber) {
                const response = await dispatch(getQuoteTemplate(idAsNumber))
                setIsLoading(false)
            }
        }
        getQuoteTemplateAsync()
    }, [dispatch, idAsNumber])

    const quoteTemplate = useSelector((state) =>
        state.quoteTemplates.quoteTemplates.find((item) => item.id === idAsNumber)
    )
    const allLineItemTypes = useSelector((state) => state.lineItemTypes.lineItemTypes)
    const feeTypes = useSelector((state) => state.feeTypes.feeTypes)
    const financingOptionTypes = useSelector(
        (state) => state.financingOptionTypes.financingOptionTypes
    )
    const categoriesById = useSelector((state) => state.categories.categories.byId)
    const categories = Object.values(categoriesById)
    const items =
        useSelector((state) => state.quoteTemplates.itemsByQuoteTemplateId[idAsNumber]) || []

    const lineItemTypeItems = items.filter((item) => item.line_item_type !== null)
    const feeTypeItems = items.filter((item) => item.fee_type !== null)

    useEffect(() => {
        setItemsState(items)
    }, [items])

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (quoteTemplate === undefined) {
        return <div>Not found</div>
    }

    console.log(quoteTemplate)

    const onSubmit = async (data: QuoteTemplateUpdate) => {
        try {
            const response = await dispatch(updateQuoteTemplate(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Quote template updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const categoriesForLineItems = getAllCategoryChildren(quoteTemplate.category_id, categories)
    if (quoteTemplate.category_id !== null && categoriesById[quoteTemplate.category_id]) {
        categoriesForLineItems.push(categoriesById[quoteTemplate.category_id])
    }
    const categoriesForLineItemsIds = categoriesForLineItems.map((item) => item.id)
    const lineItemTypes = allLineItemTypes.filter((item) =>
        // @ts-ignore
        categoriesForLineItemsIds.includes(item?.category?.id)
    )

    const handleDragEnd = async (result: DropResult) => {
        if (!result.destination) return
        const reorderedItems = Array.from(
            result.destination.droppableId === "items-line-item-types"
                ? lineItemTypeItems
                : feeTypeItems
        )
        const [removed] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, removed)

        const newState = []
        if (result.destination.droppableId === "items-line-item-types") {
            newState.push(...reorderedItems)
            newState.push(...feeTypeItems)
        } else {
            newState.push(...lineItemTypeItems)
            newState.push(...reorderedItems)
        }
        setItemsState(newState)
        const newOrder: { [key: number]: number } = {}
        reorderedItems.forEach((item, index) => {
            newOrder[item.id] = index
        })

        // console.log(newOrder)

        const response = await dispatch(reorderQuoteTemplateItems(idAsNumber, newOrder))
        if (response.status === 200) {
            enqueueSnackbar("Quote template updated", { variant: "success" })
        }
    }

    const onCreateLineItemItem = async (lineItemType: LineItemTypeType | null) => {
        if (!idAsNumber || !lineItemType) return
        setIsSending(true)
        console.log("Service Type:", { lineItemType })
        let order = 0
        if (items.length > 0) {
            order = Math.max(...items.map((item) => item.order)) + 1
        }
        const response = await dispatch(
            createQuoteTemplateItem({
                quote_template_id: idAsNumber,
                line_item_type_id: lineItemType.id,
                order,
            })
        )
        if (response.status === 201) {
            setIsSending(false)
            setLineItemTypeToAdd(null)
            enqueueSnackbar(`Service ${lineItemType.name} added to the template`, {
                variant: "success",
            })
        } else {
            setIsSending(false)
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
    }

    const onCreateFinancingOptionTypeItem = async (item: TFinancingOptionType | null) => {
        if (!idAsNumber || !item) return
        setIsSending(true)
        let order = 0
        if (items.length > 0) {
            order = Math.max(...items.map((item) => item.order)) + 1
        }
        const response = await dispatch(
            createQuoteTemplateItem({
                quote_template_id: idAsNumber,
                financing_option_type_id: item.id,
                order,
            })
        )
        if (response.status === 201) {
            setIsSending(false)
            setFinancingOptionToAdd(null)
            enqueueSnackbar(`Financing option ${item.name} added to the template`, {
                variant: "success",
            })
        } else {
            setIsSending(false)
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
    }

    const onCreateFeeTypeItem = async (feeType: FeeTypeType | null) => {
        if (!idAsNumber || !feeType) return
        setIsSending(true)
        console.log("Fee Type:", { feeType })
        let order = 0
        if (items.length > 0) {
            order = Math.max(...items.map((item) => item.order)) + 1
        }
        const response = await dispatch(
            createQuoteTemplateItem({
                quote_template_id: idAsNumber,
                fee_type_id: feeType.id,
                order,
            })
        )
        if (response.status === 201) {
            setIsSending(false)
            setFeeTypeToAdd(null)
            enqueueSnackbar(`Fee ${feeType.name} added to the template`, {
                variant: "success",
            })
        } else {
            setIsSending(false)
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
    }

    return (
        <>
            <Helmet>
                <title>Update Quote Template</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Update Quote Template"
                    links={[
                        {
                            name: "Quote Templates",
                        },
                        {
                            name: "List",
                            href: PATHS.quotes.quoteTemplates.list(),
                        },
                        {
                            name: "Update",
                        },
                    ]}
                />
                <QuoteTemplateEditForm onSubmit={onSubmit} initialData={quoteTemplate} />
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                        Services
                    </Typography>
                    <Stack direction="column" spacing={0}>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={1}
                            alignItems="center"
                            sx={{ mb: 2 }}
                        >
                            <Autocomplete
                                id="line-item-types-dropdown"
                                getOptionLabel={(option) => option.name}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: LineItemTypeType | null
                                ) => setLineItemTypeToAdd(value)}
                                value={lineItemTypeToAdd}
                                disabled={isSending}
                                options={lineItemTypes}
                                sx={{ width: "100%" }}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        // size="small"
                                        label="Add a Service"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ ml: 1 }}
                                                        onClick={
                                                            () =>
                                                                onCreateLineItemItem(
                                                                    lineItemTypeToAdd
                                                                )
                                                            // onCreateLineItem(lineItemTypeToAdd)
                                                        }
                                                        disabled={isSending}
                                                    >
                                                        Add
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Stack>

                        <Droppable droppableId="items-line-item-types">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {itemsState
                                        .filter((itm) => itm.line_item_type !== null)
                                        .map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id.toString()}
                                                index={index}
                                            >
                                                {(pr) => (
                                                    <div
                                                        ref={pr.innerRef}
                                                        {...pr.draggableProps}
                                                        {...pr.dragHandleProps}
                                                    >
                                                        <QuoteTemplateItem item={item} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        {/* </DragDropContext> */}
                    </Stack>
                    <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                        Fees
                    </Typography>
                    <Stack direction="column" spacing={0}>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={1}
                            alignItems="center"
                            sx={{ mb: 2 }}
                        >
                            <Autocomplete
                                id="fee-types-dropdown"
                                getOptionLabel={(option) => option.name}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: FeeTypeType | null
                                ) => setFeeTypeToAdd(value)}
                                value={feeTypeToAdd}
                                disabled={isSending}
                                options={feeTypes}
                                sx={{ width: "100%" }}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        // size="small"
                                        label="Add a Fee"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ ml: 1 }}
                                                        onClick={
                                                            () => onCreateFeeTypeItem(feeTypeToAdd)
                                                            // onCreateLineItem(lineItemTypeToAdd)
                                                        }
                                                        disabled={isSending}
                                                    >
                                                        Add
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                        <Droppable droppableId="items-fee-types">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {itemsState
                                        .filter((itm) => itm.fee_type !== null)
                                        .map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id.toString()}
                                                index={index}
                                            >
                                                {(pr) => (
                                                    <div
                                                        ref={pr.innerRef}
                                                        {...pr.draggableProps}
                                                        {...pr.dragHandleProps}
                                                    >
                                                        <QuoteTemplateItem item={item} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Stack>
                    <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                        Financing Options
                    </Typography>
                    <Stack direction="column" spacing={0}>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={1}
                            alignItems="center"
                            sx={{ mb: 2 }}
                        >
                            <Autocomplete
                                id="financing-option-types-dropdown"
                                getOptionLabel={(option) => option.name}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: TFinancingOptionType | null
                                ) => setFinancingOptionToAdd(value)}
                                value={financingOptionToAdd}
                                disabled={isSending}
                                options={financingOptionTypes}
                                sx={{ width: "100%" }}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        // size="small"
                                        label="Add a Financing Option"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ ml: 1 }}
                                                        onClick={
                                                            () =>
                                                                onCreateFinancingOptionTypeItem(
                                                                    financingOptionToAdd
                                                                )
                                                            // onCreateLineItem(lineItemTypeToAdd)
                                                        }
                                                        disabled={isSending}
                                                    >
                                                        Add
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                        <Droppable droppableId="items-financing-option-types">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {itemsState
                                        .filter((itm) => itm.financing_option_type !== null)
                                        .map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id.toString()}
                                                index={index}
                                            >
                                                {(pr) => (
                                                    <div
                                                        ref={pr.innerRef}
                                                        {...pr.draggableProps}
                                                        {...pr.dragHandleProps}
                                                    >
                                                        <QuoteTemplateItem
                                                            item={item}
                                                            getName={(item) =>
                                                                `${item.financing_option_type?.name} - APR ${item.financing_option_type?.apr}% - ${item.financing_option_type?.term} months`
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Stack>
                </DragDropContext>
            </Container>
        </>
    )
}
