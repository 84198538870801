import Handlebars from "handlebars"
import { LineItemType } from "src/@types/lineItem"
import { MaterialType } from "src/@types/material"
import { PackageItemType } from "src/@types/package"

type Props = {
    lineItem: LineItemType
    packageItem?: PackageItemType
    materialsById?: {
        [key: number]: MaterialType
    }
    text: string
}

Handlebars.registerHelper("padZeroes", (number: number | string, length: number) => {
    let str = number.toString()
    while (str.length < length) {
        str = `0${str}`
    }
    return str
})

const formatLineItemDescription = ({
    text,
    lineItem,
    packageItem,
    materialsById,
}: Props): string => {
    const replacements: any = {
        line_item: lineItem,
        measurements: {},
    }
    if (packageItem && materialsById && materialsById[packageItem.material_id]) {
        replacements.package_item = {
            material: materialsById[packageItem.material_id],
        }
    }
    lineItem.measurements.forEach((measurement) => {
        measurement.m_fields.forEach((field) => {
            if (!replacements.measurements[measurement.slug])
                replacements.measurements[measurement.slug] = {}
            if (replacements.measurements[measurement.slug][field.slug]) return
            replacements.measurements[measurement.slug][field.slug] = field.value || ""
        })
    })
    // console.log(replacements)
    // first pass
    let template = Handlebars.compile(text)
    text = template(replacements)
    // second pass to account for nested variables after the first pass
    template = Handlebars.compile(text)
    text = template(replacements)
    return text
}

export { formatLineItemDescription }
