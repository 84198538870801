import { LineItemCreate, LineItemUpdate } from "src/@types/lineItem"
import axios from "../utils/axios"

export type FetchLineItemsFilters = {
    quote_id?: number
}

const apiFetchLineItems = (filters?: FetchLineItemsFilters) =>
    axios.get("/api/v1/quotes/line-items/", { params: filters })

const apiFetchLineItem = (id: number) => axios.get(`/api/v1/quotes/line-items/${id}/`)

const apiUpdateLineItem = (id: number, changes: LineItemUpdate) =>
    axios.patch(`/api/v1/quotes/line-items/${id}/`, changes)

const apiCreateLineItem = async (payload: LineItemCreate) => {
    try {
        return await axios.post(`/api/v1/quotes/line-items/`, payload)
    } catch (error) {
        console.log("Error:", error)
        return error.response
    }
}

const apiDeleteLineItem = (id: number) => axios.delete(`/api/v1/quotes/line-items/${id}/`)

const apiUpdateLineItemMaterialOption = (id: number, type_id: number, option: number) =>
    axios.put(`/api/v1/quotes/line-items/${id}/materials/`, {
        type_id,
        option,
    })

export {
    apiUpdateLineItem,
    apiFetchLineItem,
    apiFetchLineItems,
    apiCreateLineItem,
    apiDeleteLineItem,
    apiUpdateLineItemMaterialOption,
}
