// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
    return `${root}${sublink}`
}

const ROOTS_DASHBOARD = "/dashboard"

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    login: "/login",
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    one: path(ROOTS_DASHBOARD, "/one"),
    two: path(ROOTS_DASHBOARD, "/two"),
    three: path(ROOTS_DASHBOARD, "/three"),
    user: {
        root: path(ROOTS_DASHBOARD, "/user"),
        four: path(ROOTS_DASHBOARD, "/user/four"),
        five: path(ROOTS_DASHBOARD, "/user/five"),
        six: path(ROOTS_DASHBOARD, "/user/six"),
    },
}

export const PATHS = {
    payroll: {
        root: "/payroll",
        runs: {
            list: "/payroll/runs",
            create: "/payroll/runs/create",
            edit: (id: number) => `/payroll/runs/${id}/edit`,
        },
        payTypes: {
            list: () => "/payroll/pay-types",
            edit: (id: number) => `/payroll/pay-types/${id}/edit`,
            create: () => "/payroll/pay-types/create",
        },
    },
    auth: {
        groups: {
            list: () => "/security/groups",
            create: () => "/security/groups/create",
            details: (id: number) => `/security/groups/${id}/`,
        },
    },
    quotes: {
        root: () => "/quotes",
        categories: {
            list: () => "/quotes/categories",
            create: () => "/quotes/categories/create",
            details: (id: number) => `/quotes/categories/${id}/`,
        },
        materialTypes: {
            list: () => "/quotes/material-types",
            create: () => "/quotes/material-types/create",
            details: (id: number) => `/quotes/material-types/${id}/`,
        },
        measurementTypes: {
            list: () => "/quotes/measurement-types",
            create: () => "/quotes/measurement-types/create",
            details: (id: number) => `/quotes/measurement-types/${id}/`,
        },
        lineItemTypes: {
            list: () => "/quotes/line-item-types",
            create: () => "/quotes/line-item-types/create",
            details: (id: number) => `/quotes/line-item-types/${id}/`,
        },
        quotes: {
            list: () => "/quotes/quotes",
            create: () => "/quotes/quotes/create",
            details: (id: number) => `/quotes/quotes/${id}/`,
            view: (id: number) => `/quotes/quotes/${id}/view`,
            export: (id: number) => `/quotes/quotes/${id}/export`,
        },
        quoteTemplates: {
            list: () => "/quotes/quote-templates",
            create: () => "/quotes/quote-templates/create",
            details: (id: number) => `/quotes/quote-templates/${id}/`,
        },
        feeTypes: {
            list: () => "/quotes/fee-types",
            create: () => "/quotes/fee-types/create",
            details: (id: number) => `/quotes/fee-types/${id}/`,
        },
        coefficients: {
            list: () => "/quotes/coefficients",
            create: () => "/quotes/coefficients/create",
            details: (id: number) => `/quotes/coefficients/${id}/`,
        },
        packageTypes: {
            list: () => "/quotes/package-types",
            create: () => "/quotes/package-types/create",
            details: (id: number) => `/quotes/package-types/${id}/`,
        },
    },
    data: {
        jobs: {
            list: "/data/jobs",
        },
    },
}
