import { createSlice, Dispatch } from "@reduxjs/toolkit"
import {
    QuoteTemplateCreate,
    QuoteTemplateUpdate,
    QuoteTemplateState,
    QuoteTemplateItemType,
    QuoteTemplateItemCreate,
    QuoteTemplateType,
} from "src/@types/quote-template"
import {
    apiCreateQuoteTemplate,
    apiDeleteQuoteTemplate,
    apiFetchQuoteTemplate,
    apiFetchQuoteTemplates,
    apiUpdateQuoteTemplate,
    apiCreateQuoteTemplateItem,
    apiDeleteQuoteTemplateItem,
    apiReorderQuoteTemplateItems,
} from "src/api/quote-templates"

const initialState: QuoteTemplateState = {
    quoteTemplates: [],
    itemsByQuoteTemplateId: {},
    isLoading: false,
}

const processQuoteTemplate = (state: QuoteTemplateState, template: QuoteTemplateType) => {
    state.itemsByQuoteTemplateId[template.id] = template.items
    state.itemsByQuoteTemplateId[template.id].sort((a, b) => a.order - b.order)
}

const slice = createSlice({
    name: "quoteTemplates",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        fetchQuoteTemplatesSuccess: (state, action) => {
            state.isLoading = false
            state.quoteTemplates = action.payload
            state.quoteTemplates.forEach((template) => processQuoteTemplate(state, template))
        },
        fetchQuoteTemplateSuccess: (state, action) => {
            state.isLoading = false
            const template = action.payload
            const index = state.quoteTemplates.findIndex((item) => item.id === template.id)
            if (index !== -1) {
                state.quoteTemplates[index] = template
            } else {
                state.quoteTemplates.push(template)
            }
            processQuoteTemplate(state, template)
        },
        createQuoteTemplateSuccess: (state, action) => {
            state.isLoading = false
            state.quoteTemplates.push(action.payload)
        },
        deleteQuoteTemplateSuccess: (state, action) => {
            state.isLoading = false
            state.quoteTemplates = state.quoteTemplates.filter((item) => item.id !== action.payload)
        },
        createItemSuccess: (state, action) => {
            state.isLoading = false
            const item: QuoteTemplateItemType = action.payload
            const template_id = item.template_id
            if (template_id) {
                if (!state.itemsByQuoteTemplateId[template_id]) {
                    state.itemsByQuoteTemplateId[template_id] = []
                }
                state.itemsByQuoteTemplateId[template_id].push(item)
                state.itemsByQuoteTemplateId[template_id].sort((a, b) => a.order - b.order)
            }
        },
        deleteLineItemSuccess(state, action) {
            state.isLoading = false
            const item: QuoteTemplateItemType = action.payload
            const template_id = item.template_id
            if (template_id && state.itemsByQuoteTemplateId[template_id] !== undefined) {
                state.itemsByQuoteTemplateId[template_id] = state.itemsByQuoteTemplateId[
                    template_id
                ].filter((i) => i.id !== item.id)
            }
        },
        reorderItemsSuccess(state, action) {
            state.isLoading = false
            const template: QuoteTemplateType = action.payload
            if (template) {
                console.log("reorderItemsSuccess", { action })
                state.itemsByQuoteTemplateId[template.id] = template.items
                state.itemsByQuoteTemplateId[template.id].sort((a, b) => a.order - b.order)
            }
        },
    },
})

export function getQuoteTemplates() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchQuoteTemplates()
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteTemplatesSuccess(response.data.results))
        }
        return response
    }
}

export function getQuoteTemplate(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiFetchQuoteTemplate(id)
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteTemplateSuccess(response.data))
        }
        return response
    }
}

export function createQuoteTemplate(data: QuoteTemplateCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateQuoteTemplate(data)
        if (response.status === 201) {
            dispatch(slice.actions.createQuoteTemplateSuccess(response.data))
        }
        return response
    }
}

export function updateQuoteTemplate(id: number, data: QuoteTemplateUpdate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiUpdateQuoteTemplate(id, data)
        if (response.status === 200) {
            dispatch(slice.actions.fetchQuoteTemplateSuccess(response.data))
        }
        return response
    }
}

export function deleteQuoteTemplate(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteQuoteTemplate(id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteQuoteTemplateSuccess(id))
        }
        return response
    }
}

export function createQuoteTemplateItem(data: QuoteTemplateItemCreate) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiCreateQuoteTemplateItem(data)
        if (response.status === 201) {
            dispatch(slice.actions.createItemSuccess(response.data))
        }
        return response
    }
}

export function deleteQuoteTemplateItem(item: QuoteTemplateItemType) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiDeleteQuoteTemplateItem(item.id)
        if (response.status === 204) {
            dispatch(slice.actions.deleteLineItemSuccess(item))
        }
        return response
    }
}

export function reorderQuoteTemplateItems(id: number, order: { [key: number]: number }) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        const response = await apiReorderQuoteTemplateItems(id, order)
        if (response.status === 200) {
            dispatch(slice.actions.reorderItemsSuccess(response.data))
        }
        return response
    }
}

export default slice.reducer
