import { useEffect, useRef, useState } from "react"
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    // Autocomplete,
    Button,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material"
import { LineItemType } from "src/@types/lineItem"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { useDispatch, useSelector } from "src/redux/store"
import { useSnackbar } from "notistack"
import {
    createMeasurement,
    deleteLineItem,
    // deleteMaterialOption,
    updateLineItem,
    // updateMaterialOption,
} from "src/redux/slices/quotes"
import { MaterialTypeType } from "src/@types/material-type"
import Measurement from "./measurement"
import EditLineItemDialog from "./edit-line-item-dialog"

type Props = {
    lineItem: LineItemType
    expanded: boolean
    setExpanded: (value: number | null) => void
}

export default function LineItemDetails({ lineItem, expanded, setExpanded }: Props) {
    const accordionRef = useRef<HTMLDivElement | null>(null)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        if (expanded && accordionRef.current) {
            setTimeout(() => {
                if (!accordionRef.current) return
                let scrollTo = accordionRef.current.offsetTop - 60
                if (scrollTo < 0) scrollTo = 0
                window.scrollTo({ top: scrollTo, behavior: "smooth" })
            }, 100)
        }
    }, [expanded])

    const [editedItem, setEditedItem] = useState<LineItemType | null>(null)
    const [location, setLocation] = useState<string>(lineItem.location || "")
    useEffect(() => {
        setLocation(lineItem.location || "")
    }, [lineItem.location])

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const measurements = useSelector(
        (state) => state.quotes.measurementsByLineItemId[lineItem.id] || []
    )
    const materials = useSelector((state) => state.quotes.materialsByLineItemId[lineItem.id] || [])

    const onDelete = async () => {
        if (window.confirm("Are you sure you want to delete this Service?")) {
            const response = await dispatch(deleteLineItem(lineItem))
            if (response.status === 200) {
                enqueueSnackbar("Service deleted successfully", { variant: "success" })
            } else {
                enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
            }
        }
    }

    const onAddMeasurement = async (id?: number) => {
        const measurementTypeId =
            id ||
            lineItem.type.measurements_default[0]?.id ||
            lineItem.type.measurements_possible[0]?.id ||
            null
        if (!measurementTypeId) {
            enqueueSnackbar("Cannot add a measurement", { variant: "error" })
            return
        }
        const response = await dispatch(createMeasurement(lineItem.id, measurementTypeId))
        if (response.status === 201) {
            enqueueSnackbar("Measurement added successfully", { variant: "success" })
        }
    }

    const materialTypesById: {
        [key: number]: MaterialTypeType
    } = {}

    lineItem.materials_possible.forEach((materialType) => {
        materialTypesById[materialType.id] = materialType
    })

    const currentMaterialOptions: {
        [key: number]: MaterialTypeType | null
    } = {
        1: null,
        2: null,
    }
    materials.forEach((material) => {
        currentMaterialOptions[material.option] = materialTypesById[material.type_id]
    })

    // const onChangeMaterial = async (event: any, value: MaterialTypeType | null, option: number) => {
    //     if (!value) {
    //         const materialToDelete = materials.find((material) => material.option === option)
    //         if (!materialToDelete) return
    //         const response = await dispatch(deleteMaterialOption(materialToDelete))
    //         if (response.status === 204) {
    //             enqueueSnackbar("Material removed successfully", { variant: "success" })
    //         }
    //         return
    //     }
    //     const response = await dispatch(updateMaterialOption(lineItem.id, value.id, option))
    //     if (response.status === 200) {
    //         enqueueSnackbar("Material updated successfully", { variant: "success" })
    //     }
    // }

    const onChangeLocation = async (event: any) => {
        const response = await dispatch(updateLineItem(lineItem.id, { location }))
        if (response.status === 200) {
            enqueueSnackbar("Location updated successfully", { variant: "success" })
        }
    }

    const measurementsPossible = lineItem.type.measurements_possible

    return (
        // <FormProvider methods={methods} onSubmit={() => { }}>
        <>
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded(expanded ? null : lineItem.id)}
                ref={accordionRef}
                TransitionProps={{ timeout: 0 }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{
                        backgroundColor: lineItem.value > 0 ? "#CCC" : "white",
                        borderTop: "1px solid #DDD",
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={0}
                        justifyContent="space-between"
                        width="100%"
                        paddingRight={2}
                    >
                        <Typography>
                            <b>{lineItem.name}</b>
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton
                                size="small"
                                color="default"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setEditedItem(lineItem)
                                }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                color="default"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onDelete()
                                }}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    {/* <FormProvider methods={methods} onSubmit={() => { }}> */}
                    <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
                        <TextField
                            id="location"
                            label="Location"
                            // multiline
                            // rows={2}
                            fullWidth
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            onBlur={onChangeLocation}
                        />
                        <Divider color="disabled" />
                        {measurements.map((measurement, i) => (
                            <Measurement
                                key={`measurement.${measurement.id}`}
                                measurement={measurement}
                                pos={i}
                                lineItemMeasurements={measurements}
                            />
                        ))}
                        {/* <Typography variant="subtitle1">Materials</Typography>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={1} sx={{ mt: 2 }}>
                        <Autocomplete
                            id="material-option-1"
                            getOptionLabel={(option) => option.name}
                            onChange={(e: SyntheticEvent, value: MaterialTypeType | null) =>
                                onChangeMaterial(e, value, 1)
                            }
                            value={currentMaterialOptions[1] || null}
                            options={lineItem.materials_possible}
                            sx={{ width: "100%", marginBottom: 2 }}
                            renderInput={(params) => <TextField {...params} label="Option 1" />}
                        />
                        <Autocomplete
                            id="material-option-2"
                            getOptionLabel={(option) => option.name}
                            onChange={(e: SyntheticEvent, value: MaterialTypeType | null) =>
                                onChangeMaterial(e, value, 2)
                            }
                            value={currentMaterialOptions[2] || null}
                            options={lineItem.materials_possible}
                            sx={{ width: "100%", marginBottom: 2 }}
                            renderInput={(params) => <TextField {...params} label="Option 2" />}
                        />
                    </Stack> */}
                    </Stack>
                </AccordionDetails>
                <AccordionActions>
                    <Button
                        size="small"
                        color="primary"
                        // onClick={onAddMeasurement}
                        onClick={
                            measurementsPossible.length === 1
                                ? () => onAddMeasurement()
                                : handleOpenMenu
                        }
                        endIcon={measurementsPossible.length === 1 ? null : <ArrowDropDownIcon />}
                    >
                        Add Measurement
                    </Button>
                    <Menu
                        id="create-quote-from-template-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                    >
                        {measurementsPossible.map((item) => (
                            <MenuItem
                                key={item.id}
                                onClick={(e) => {
                                    onAddMeasurement(item.id)
                                    handleCloseMenu()
                                }}
                                dense
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Menu>
                    <Button size="small" color="error" onClick={onDelete}>
                        Delete Service
                    </Button>
                </AccordionActions>
            </Accordion>
            <EditLineItemDialog onClose={() => setEditedItem(null)} lineItem={editedItem} />
        </>

        // </FormProvider>
    )
}
