import { useNavigate } from "react-router"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, Stack } from "@mui/material"
import { RHFTextField } from "src/components/hook-form"
import { PATHS } from "src/routes/paths"
import { QuoteTemplateCreate, QuoteTemplateType } from "src/@types/quote-template"
import CategorySelectTree from "../category-select"

type QuoteTemplateFormData = {
    id: number | null
    name: string
    description: string
    notes: string
    category_id: number | null
}

type Props = {
    initialData?: QuoteTemplateType
    onSubmit: (data: QuoteTemplateCreate) => void
}

export default function QuoteTemplateEditForm({ onSubmit, initialData }: Props) {
    const navigate = useNavigate()

    const defaultValues = {
        id: null,
        name: "",
        description: "",
        notes: "",
        category_id: null,
    }

    const schema = Yup.object().shape({
        // description: Yup.string().required("Description is required"),
        name: Yup.string().required("Name is required"),
        category_id: Yup.number().required("Category is required"),
    })

    const methods = useForm<QuoteTemplateFormData>({
        resolver: yupResolver(schema),
        defaultValues,
    })

    useEffect(() => {
        console.log("initialData", initialData)
        if (initialData) {
            methods.reset(initialData)
        }
    }, [initialData, methods])

    const handleSubmit = (data: QuoteTemplateFormData) => {
        if (data.category_id === null) return

        const payload = {
            id: data.id,
            name: data.name,
            notes: data.notes,
            description: data.description,
            category_id: data.category_id,
            package_type_ids: [1, 2, 3],
        }
        onSubmit(payload)
    }

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        spacing={2}
                        justifyContent="space-between"
                    >
                        <Stack direction="column" spacing={2} flex={1}>
                            <RHFTextField name="name" label="Name" />
                            <RHFTextField
                                name="description"
                                label="Description"
                                helperText="NOT copied to a new quote"
                            />
                            <RHFTextField
                                name="notes"
                                label="Notes"
                                helperText="Copied to a new quote"
                                multiline
                                rows={4}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} width={{ xs: "100%", md: "50%" }}>
                            {/* {initialData ? (
                                <Typography variant="body1">
                                    <b>Category:</b> {initialData.category?.name || "No Category"}
                                </Typography>
                            ) : ( */}
                            <CategorySelectTree
                                current={initialData?.category_id?.toString() || "none"}
                                onChange={(value) => {
                                    console.log(value)
                                    methods.setValue("category_id", value)
                                }}
                                disable={[]}
                                emptyLabel="No Category"
                            />
                            {/* )} */}
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.quotes.quoteTemplates.list())}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={methods.handleSubmit(handleSubmit)}
                >
                    {initialData === undefined ? "Create" : "Save"}
                </Button>
            </Stack>
        </FormProvider>
    )
}
