import { Navigate, useRoutes } from "react-router-dom"
// import PayrollRunsList from "src/pages/payroll/runs/PayrollRunsList"
// import CreatePayrollRun from "src/pages/payroll/runs/CreatePayrollRun"
// import EditPayrollRun from "src/pages/payroll/runs/EditPayrollRun"
import JobsList from "src/pages/data/jobs/JobsList"
// import PayTypesList from "src/pages/payroll/payTypes/payTypesList"
// import EditPayTypeView from "src/pages/payroll/payTypes/edit"
// import CreatePayTypeView from "src/pages/payroll/payTypes/create"
// auth
import CategoriesList from "src/pages/quotes/categories/categories-list"
import CategoryCreate from "src/pages/quotes/categories/category-create"
import CategoryDetailsPage from "src/pages/quotes/categories/category-details"
import MaterialTypesList from "src/pages/quotes/material-types/material-types-list"
import MaterialTypeCreatePage from "src/pages/quotes/material-types/material-type-create"
import MaterialTypeDetailsPage from "src/pages/quotes/material-types/material-type-details"
import MeasurementTypesListPage from "src/pages/quotes/measurement-types/measurement-types-list"
import MeasurementTypeCreatePage from "src/pages/quotes/measurement-types/measurement-types-create"
import MeasurementTypeDetailsPage from "src/pages/quotes/measurement-types/measurement-type-details"
import LineItemTypesListPage from "src/pages/quotes/line-item-types/line-item-types-list"
import LineItemTypeCreatePage from "src/pages/quotes/line-item-types/line-item-type-create"
import LineItemTypeDetailsPage from "src/pages/quotes/line-item-types/line-item-type-details"
import QuotesListPage from "src/pages/quotes/quotes/quotes-list"
import QuotesCreatePage from "src/pages/quotes/quotes/quote-create"
import QuotesDetailsPage from "src/pages/quotes/quotes/quote-details"
import QuoteViewPage from "src/pages/quotes/quotes/quote-view"
import QuoteTemplatesListPage from "src/pages/quotes/quote-templates/quote-templates-list"
import QuoteTemplateCreatePage from "src/pages/quotes/quote-templates/quote-template-create"
import QuoteTemplateDetailsPage from "src/pages/quotes/quote-templates/quote-template-details"
import FeeTypesListPage from "src/pages/quotes/fee-types/fee-types-list"
import FeeTypeCreatePage from "src/pages/quotes/fee-types/fee-type-create"
import FeeTypeDetailsPage from "src/pages/quotes/fee-types/fee-type-details"
import CoefficientsListPage from "src/pages/quotes/coefficients/coefficient-containers-list"
import CoefficientCreatePage from "src/pages/quotes/coefficients/coefficient-container-create"
import CoefficientDetailsPage from "src/pages/quotes/coefficients/coefficient-container-details"
import GroupsListPage from "src/pages/auth/groups/groups-list"
import GroupCreatePage from "src/pages/auth/groups/group-create"
import GroupDetailsPage from "src/pages/auth/groups/group-details"
import QuoteExportPage from "src/pages/quotes/quotes/quote-export"
// import MainPage from "src/pages/MainPage"
import { Permissions } from "src/@types/security"
import PermissionBasedGuard from "src/auth/PermissionBasedGuard"
import PackageTypesListPage from "src/pages/quotes/package-types/package-types-list"
import PackageTypeCreatePage from "src/pages/quotes/package-types/package-type-create"
import PackageTypeDetailsPage from "src/pages/quotes/package-types/package-type-details"
import AuthGuard from "../auth/AuthGuard"
import GuestGuard from "../auth/GuestGuard"
// layouts
import CompactLayout from "../layouts/compact"
import DashboardLayout from "../layouts/dashboard"
// config
import { PATH_AFTER_LOGIN } from "../config-global"
//
import { Page404, LoginPage } from "./elements"

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: "/",
            element: <Navigate to="/quotes" />,
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                {
                    path: "login",
                    element: (
                        <GuestGuard>
                            <LoginPage />
                        </GuestGuard>
                    ),
                },
            ],
        },
        {
            path: "/security",
            element: (
                <AuthGuard>
                    <PermissionBasedGuard permission={Permissions.GROUP_VIEW} hasContent>
                        <DashboardLayout />
                    </PermissionBasedGuard>
                </AuthGuard>
            ),
            children: [
                { path: "groups", element: <GroupsListPage /> },
                { path: "groups/create", element: <GroupCreatePage /> },
                { path: "groups/:id/", element: <GroupDetailsPage /> },
            ],
        },
        {
            path: "/quotes",
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                // { path: '/', element: <Navigate to="/quotes/categories/" replace /> },
                { path: "categories", element: <CategoriesList /> },
                { path: "categories/create", element: <CategoryCreate /> },
                { path: "categories/:id/", element: <CategoryDetailsPage /> },
                { path: "material-types", element: <MaterialTypesList /> },
                { path: "material-types/create", element: <MaterialTypeCreatePage /> },
                { path: "material-types/:id/", element: <MaterialTypeDetailsPage /> },
                { path: "measurement-types", element: <MeasurementTypesListPage /> },
                { path: "measurement-types/create", element: <MeasurementTypeCreatePage /> },
                { path: "measurement-types/:id/", element: <MeasurementTypeDetailsPage /> },
                { path: "line-item-types", element: <LineItemTypesListPage /> },
                { path: "line-item-types/create", element: <LineItemTypeCreatePage /> },
                { path: "line-item-types/:id/", element: <LineItemTypeDetailsPage /> },
                { path: "quotes", element: <QuotesListPage /> },
                { path: "quotes/create", element: <QuotesCreatePage /> },
                { path: "quotes/:id/", element: <QuotesDetailsPage /> },
                { path: "quotes/:id/view", element: <QuoteViewPage /> },
                {
                    path: "quotes/:id/export",
                    element: (
                        <PermissionBasedGuard permission={Permissions.QUOTE_EXPORT} hasContent>
                            <QuoteExportPage />
                        </PermissionBasedGuard>
                    ),
                },
                { path: "quote-templates", element: <QuoteTemplatesListPage /> },
                { path: "quote-templates/create", element: <QuoteTemplateCreatePage /> },
                { path: "quote-templates/:id/", element: <QuoteTemplateDetailsPage /> },
                { path: "fee-types", element: <FeeTypesListPage /> },
                { path: "fee-types/create", element: <FeeTypeCreatePage /> },
                { path: "fee-types/:id/", element: <FeeTypeDetailsPage /> },
                { path: "coefficients", element: <CoefficientsListPage /> },
                { path: "coefficients/create", element: <CoefficientCreatePage /> },
                { path: "coefficients/:id/", element: <CoefficientDetailsPage /> },
                {
                    path: "package-types",
                    element: (
                        <PermissionBasedGuard permission={Permissions.PACKAGE_TYPE_VIEW} hasContent>
                            <PackageTypesListPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "package-types/create",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.PACKAGE_TYPE_CREATE}
                            hasContent
                        >
                            <PackageTypeCreatePage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "package-types/:id/",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.PACKAGE_TYPE_UPDATE}
                            hasContent
                        >
                            <PackageTypeDetailsPage />
                        </PermissionBasedGuard>
                    ),
                },
            ],
        },
        // {
        //     path: "/payroll",
        //     element: (
        //         <AuthGuard>
        //             <DashboardLayout />
        //         </AuthGuard>
        //     ),
        //     children: [
        //         // { path: '/', element: <Navigate to="/payroll/runs" replace />},
        //         { path: "runs", element: <PayrollRunsList /> },
        //         { path: "runs/create", element: <CreatePayrollRun /> },
        //         { path: "runs/:id/edit", element: <EditPayrollRun /> },
        //         { path: "pay-types", element: <PayTypesList /> },
        //         { path: "pay-types/create", element: <CreatePayTypeView /> },
        //         { path: "pay-types/:id/edit", element: <EditPayTypeView /> },
        //     ],
        // },
        {
            path: "/data",
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { path: "jobs", element: <JobsList /> },
                // { path: 'runs/create', element: <CreatePayrollRun /> }
            ],
        },
        {
            element: <CompactLayout />,
            children: [{ path: "404", element: <Page404 /> }],
        },
        // { path: "/", element: <MainPage />},
        { path: "*", element: <Navigate to="/404" replace /> },
    ])
}
