import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router"
import AddIcon from "@mui/icons-material/Add"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { deleteContainer, getContainers } from "src/redux/slices/containers"
import { ContainerType } from "src/@types/containers"

export default function CoefficientsListPage() {
    const dispatch = useDispatch()
    const containers = useSelector((state) =>
        state.containers.containers.filter((container) => container.type === "coefficient")
    )

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getContainers({ type: "coefficient" }))
    }, [dispatch])

    const onDelete = async (container: ContainerType) => {
        if (!window.confirm("Are you sure you want to delete this coefficient?")) return
        const response = await dispatch(deleteContainer(container.id))
        if (response.status === 204) {
            console.log(`Coefficient ${container.name} deleted successfully`)
        }
    }

    console.log(containers)

    return (
        <>
            <Helmet>
                <title>Coefficients</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Coefficients"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Coefficients",
                            href: PATHS.quotes.coefficients.list(),
                        },
                        {
                            name: "List",
                        },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(PATHS.quotes.coefficients.create())}
                        >
                            Create Coefficient
                        </Button>
                    }
                />
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    {/* <TableCell>Description</TableCell> */}
                                    <TableCell>Value</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {containers.map((container) => (
                                    <TableRow key={container.id} hover>
                                        {/* <TableCell>{container.id}</TableCell> */}
                                        <TableCell>{container.name}</TableCell>
                                        {/* <TableCell>{container.description}</TableCell> */}
                                        <TableCell>{container.value}</TableCell>
                                        <TableCell>
                                            <ButtonGroup variant="outlined" size="small">
                                                <Button
                                                    onClick={() =>
                                                        navigate(
                                                            PATHS.quotes.coefficients.details(
                                                                container.id
                                                            )
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color="error"
                                                    onClick={() => onDelete(container)}
                                                >
                                                    Delete
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
