import { LineItemType } from "src/@types/lineItem"
import { MaterialType } from "src/@types/material"
import { PackageItemType, PackageType } from "src/@types/package"
import { formatLineItemDescription } from "src/services/line-item-service"
import { useDispatch } from "src/redux/store"
import {
    addPackageItem,
    deletePackageItem,
    updateLineItem,
    updateMaterial,
} from "src/redux/slices/quotes"
import ChooseProductCell from "./choose-option-cell"
import EditNumberCell from "./edit-number-cell"
import EditTextCell from "./edit-text-cell"

type Props = {
    lineItem: LineItemType
    packages: PackageType[]
    packageItems: PackageItemType[]
    materials: MaterialType[]
}

export default function LineItemRowTwoOptions({
    lineItem,
    packages,
    packageItems,
    materials,
}: Props) {
    const dispatch = useDispatch()

    const materialOption1 = materials.filter((material) => material.option === 1)[0]
    const materialOption2 = materials.filter((material) => material.option === 2)[0] || null
    const materialOptions: { [key: number]: MaterialType } = {}
    materialOptions[materialOption1.id] = materialOption1
    if (materialOption2) materialOptions[materialOption2.id] = materialOption2

    const packageAlaCarte = packages[0]
    const package1 = packages[1]
    const package2 = packages[2]
    const package3 = packages[3]

    const packageAlaCarteItem =
        packageItems.filter((packageItem) => packageItem.package_id === packageAlaCarte.id)[0] ||
        null
    const package1Item =
        packageItems.filter((packageItem) => packageItem.package_id === package1.id)[0] || null
    const package2Item =
        packageItems.filter((packageItem) => packageItem.package_id === package2.id)[0] || null
    const package3Item =
        packageItems.filter((packageItem) => packageItem.package_id === package3?.id)[0] || null

    const packageAlaCarteOption = packageAlaCarteItem
        ? materialOptions[packageAlaCarteItem.material_id].option
        : null
    const package1Option = package1Item ? materialOptions[package1Item.material_id].option : null
    const package2Option = package2Item ? materialOptions[package2Item.material_id].option : null
    const package3Option = package3Item ? materialOptions[package3Item.material_id].option : null

    const onChangeOption = async (packageId: number, option: number | null) => {
        if (option === null) {
            const packageItem = packageItems.filter((pi) => pi.package_id === packageId)[0]
            if (packageItem) {
                dispatch(deletePackageItem(packageItem))
            }
            return
        }
        const material = option === 1 ? materialOption1 : materialOption2

        const response = dispatch(
            addPackageItem({
                package_id: packageId,
                line_item_id: lineItem.id,
                material_id: material.id,
            })
        )
        console.log(response)
    }

    const onUpdateMaterialTotal = (materialId: number | null, total: number) => {
        if (!materialId) return
        const response = dispatch(updateMaterial(materialId, { total }))
        console.log(response)
    }

    return (
        <tr key={lineItem.id}>
            <ChooseProductCell
                state={package1Option}
                bgColor="orange"
                onChange={(value) => onChangeOption(package1.id, value)}
                materials={materials}
            />
            <ChooseProductCell
                state={package2Option}
                bgColor="yellow"
                onChange={(value) => onChangeOption(package2.id, value)}
                materials={materials}
            />
            <ChooseProductCell
                state={package3Option}
                bgColor="LawnGreen"
                onChange={(value) => onChangeOption(package3.id, value)}
                materials={materials}
            />
            <td className="text-regular">{lineItem.name}</td>
            <EditTextCell
                state={lineItem.description}
                formatter={(state) => formatLineItemDescription({ text: state, lineItem })}
                onChange={(value) => {
                    dispatch(updateLineItem(lineItem.id, { description: value }))
                }}
                name={lineItem.name}
                // textStyles={{ fontSize: 8 }}
            />
            {/* <td>{lineItem.description}</td> */}
            <EditNumberCell
                state={materialOption1?.total || null}
                onChange={(value) => onUpdateMaterialTotal(materialOption1?.id, value)}
                name={lineItem.name}
            />
            <EditNumberCell
                state={materialOption2?.total || null}
                onChange={(value) => onUpdateMaterialTotal(materialOption2?.id, value)}
                name={lineItem.name}
                disabled={!materialOption2}
            />
            <ChooseProductCell
                state={packageAlaCarteOption}
                onChange={(value) => onChangeOption(packageAlaCarte?.id, value)}
                materials={materials}
            />
        </tr>
    )
}
