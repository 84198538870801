import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import AddIcon from "@mui/icons-material/Add"
import { useNavigate } from "react-router"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { deleteMaterialType, getMaterialTypes } from "src/redux/slices/material-types"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { Category } from "src/@types/category"
import { getCategories } from "src/redux/slices/categories"
import { MaterialTypeType } from "src/@types/material-type"

const getCategoriesPath = (id: number, categoriesById: { [key: number]: Category }): string[] => {
    if (!id || !categoriesById[id]) {
        return []
    }
    const category = categoriesById[id]
    if (category.parent_id === null) {
        return [category.name]
    }
    return [...getCategoriesPath(category.parent_id, categoriesById), category.name]
}

export default function MaterialTypesList() {
    const dispatch = useDispatch()
    const { materialTypes: materials } = useSelector((state) => state.materialTypes)
    const materialsSorted = [...materials]
    materialsSorted.sort((a, b) => a.name.localeCompare(b.name))
    const {
        categories: { byId: categoriesById },
    } = useSelector((state) => state.categories)

    useEffect(() => {
        dispatch(getMaterialTypes())
        dispatch(getCategories())
    }, [dispatch])

    const onDelete = async (material: MaterialTypeType) => {
        if (!window.confirm("Are you sure you want to delete this Material?")) return
        const response = await dispatch(deleteMaterialType(material.id))
        if (response.status === 204) {
            console.log(`Material ${material.name} deleted successfully`)
        }
    }

    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>Material Types</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Material Types"
                    links={[
                        {
                            name: "Quotes",
                        },
                        {
                            name: "Material Types",
                            href: PATHS.quotes.materialTypes.list(),
                        },
                        {
                            name: "List",
                        },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(PATHS.quotes.materialTypes.create())}
                        >
                            Create Material Type
                        </Button>
                    }
                />
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>ID</TableCell> */}
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Display Name</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Base Unit</TableCell>
                                    <TableCell>Price Unit</TableCell>
                                    <TableCell>Prices #</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {materialsSorted.map((material) => (
                                    <TableRow key={material.id} hover>
                                        {/* <TableCell>{material.id}</TableCell> */}
                                        <TableCell>{material.name}</TableCell>
                                        <TableCell>{material.description}</TableCell>
                                        <TableCell>{material.display_name}</TableCell>
                                        <TableCell>
                                            {material.category_id &&
                                                getCategoriesPath(
                                                    material.category_id,
                                                    categoriesById
                                                ).join(" > ")}
                                        </TableCell>
                                        <TableCell>{material.base_unit}</TableCell>
                                        <TableCell>{material.price_unit}</TableCell>
                                        <TableCell>{material.prices.length}</TableCell>
                                        <TableCell>
                                            <ButtonGroup variant="outlined" size="small">
                                                <Button
                                                    onClick={() =>
                                                        navigate(
                                                            PATHS.quotes.materialTypes.details(
                                                                material.id
                                                            )
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    color="error"
                                                    onClick={() => onDelete(material)}
                                                >
                                                    Delete
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Container>
        </>
    )
}
