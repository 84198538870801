import { useNavigate } from "react-router"
import { Unit } from "src/@types/units"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import FormProvider from "src/components/hook-form/FormProvider"
import { Button, Card, CardContent, MenuItem, Stack } from "@mui/material"
import { RHFSelect, RHFTextField } from "src/components/hook-form"
import { LoadingButton } from "@mui/lab"
import { PATHS } from "src/routes/paths"
import CategorySelectTree from "../category-select"

type MaterialFormData = {
    id: number | null
    name: string
    description: string
    display_name: string
    category_id: number | null
    base_unit: Unit
    price_unit: Unit
    formula: string
}

type MaterialFormProps = {
    initialData?: MaterialFormData
    onSubmit: (data: MaterialFormData) => void
}

export default function MaterialForm({ initialData, onSubmit }: MaterialFormProps) {
    const navigate = useNavigate()

    const defaultValues = {
        id: null,
        name: "",
        description: "",
        display_name: "",
        category_id: null,
        base_unit: Unit.FT,
        price_unit: Unit.FT,
        formula: "{value}",
    }

    const MaterialSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        // description: Yup.string().required("Description is required"),
        // category_id: Yup.number().nullable().required('Category is required'),
        base_unit: Yup.string().oneOf(Object.values(Unit)).required("Base Unit is required"),
    })

    const methods = useForm<MaterialFormData>({
        resolver: yupResolver(MaterialSchema),
        defaultValues,
    })

    useEffect(() => {
        if (initialData) {
            methods.reset(initialData)
        }
    }, [initialData, methods])

    return (
        <FormProvider methods={methods}>
            <Card>
                <CardContent>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        spacing={2}
                        justifyContent="space-between"
                    >
                        <Stack direction="column" spacing={2} width={{ xs: "100%", md: "50%" }}>
                            <RHFTextField name="name" label="Name" required autoFocus fullWidth />
                            <RHFTextField name="description" label="Description" fullWidth />
                            <RHFTextField name="display_name" label="Display Name" fullWidth />
                            <RHFSelect name="base_unit" label="Base Unit" required fullWidth>
                                {Object.values(Unit).map((unit) => (
                                    <MenuItem key={unit} value={unit}>
                                        {unit.toLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFSelect name="price_unit" label="Price Unit" required fullWidth>
                                {Object.values(Unit).map((unit) => (
                                    <MenuItem key={unit} value={unit}>
                                        {unit.toLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFTextField name="formula" label="Formula" fullWidth required />
                        </Stack>
                        <Stack direction="column" spacing={2} width={{ xs: "100%", md: "50%" }}>
                            <CategorySelectTree
                                current={initialData?.category_id?.toString() || "none"}
                                onChange={(value) => {
                                    console.log(value)
                                    methods.setValue("category_id", value)
                                }}
                                disable={[]}
                                emptyLabel="No Category"
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                sx={{ mt: 2 }}
                alignItems="center"
            >
                <Button
                    size="small"
                    color="error"
                    onClick={() => navigate(PATHS.quotes.materialTypes.list())}
                >
                    Cancel
                </Button>
                <LoadingButton
                    size="small"
                    variant="contained"
                    loading={false}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    {initialData === undefined ? "Create" : "Save"}
                </LoadingButton>
            </Stack>
        </FormProvider>
    )
}
